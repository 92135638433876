export const ONE_WEEK = "1week";
export const ONE_MONTH = "1month";
export const THREE_MONTH = "3month";
export const SIX_MONTH = "6month";

export const ALL = "all";
export const YESTERDAY = "yesterday";
export const TODAY = "today";

export const DATE_FILTER_LIST_DEFAULT = [
  { id: ONE_WEEK, text: "1주일" },
  { id: ONE_MONTH, text: "1개월" },
  { id: THREE_MONTH, text: "3개월" },
  { id: SIX_MONTH, text: "6개월" },
];

export const DATE_FILTER_LIST_TYPE2 = [
  { id: ALL, text: "전체" },
  { id: YESTERDAY, text: "전일" },
  { id: TODAY, text: "오늘" },
  { id: ONE_MONTH, text: "1개월" },
  { id: THREE_MONTH, text: "3개월" },
];
