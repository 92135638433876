<template>
  <BoardBase
    :class="[
      disableHover || this.dataList.length < 1 ? 'tbl_nohover' : '',
      isScroll ? 'tbl_scroll' : '',
    ]"
    :style="cssProps"
  >
    <div :class="{ horizontal_scroll: isHorizontalScroll }">
      <div
        v-if="isScroll"
        class="inner_scroll"
        :style="{ height: scrollHeight, maxHeight: scrollMaxHeight, minHeight: scrollMinHeight }"
      >
        <table>
          <colgroup>
            <slot name="colgroup" />
          </colgroup>
          <thead>
            <tr>
              <slot name="tr" />
            </tr>
          </thead>
          <tbody>
            <slot v-if="!this.dataList || this.dataList.length < 1" name="emptyList" />
            <slot v-else name="rows" />
          </tbody>
        </table>
      </div>
      <table v-else>
        <colgroup>
          <slot name="colgroup" />
        </colgroup>
        <thead>
          <tr>
            <slot name="tr" />
          </tr>
        </thead>
        <tbody>
          <slot v-if="!this.dataList || this.dataList.length < 1" name="emptyList" />
          <slot v-else name="rows" />
        </tbody>
      </table>
    </div>
    <Pagination
      v-if="showPagination"
      :startPageIndex="startPageIndex"
      :showPageCount="showPageCount < 0 ? 0 : showPageCount"
      :selectedPageIndex="currentPageIndex"
      :enabledFirst="enabledFirst"
      :enabledEnd="enabledEnd"
      :enabledPrevPage="enabledPrevPage"
      :enabledNextPage="enabledNextPage"
      :enabledPrevGroup="enabledPrevGroup"
      :enabledNextGroup="enabledNextGroup"
      @onClickPageNum="onClickPageNum"
      @onClickEndPage="onClickEndPage"
      @onClickPrevGroup="onClickPrevGroup"
      @onClickNextGroup="onClickNextGroup"
    />
    <!-- <slot name="util" /> -->
  </BoardBase>
</template>

<script>
import Pagination from "./Pagination";
import BoardBase from "./BoardBase";

export default {
  name: "Board",
  components: {
    Pagination,
    BoardBase,
  },
  props: {
    dataList: Array,
    isScroll: {
      type: Boolean,
      default: false,
    },
    isHorizontalScroll: {
      type: Boolean,
      default: false,
    },
    scrollWidth: String,
    scrollHeight: String,
    scrollMaxHeight: String,
    scrollMinHeight: String,
    showPagination: {
      type: Boolean,
      default: true,
    },
    currentPageIndex: Number,
    maxRowCount: Number,
    maxPaginationCount: Number,
    totalPageCount: Number,
    disableHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    startPageIndex() {
      const paginationGroupIndex = this.getPaginationGroupIndex(this.currentPageIndex);

      const result = paginationGroupIndex * this.maxPaginationCount;

      return result;
    },
    showPageCount() {
      const endPageIndex = this.getEndPageIndex(this.startPageIndex);
      // if( endPageIndex < 0 )  return 0;

      const result = endPageIndex - this.startPageIndex + 1;

      return result;
    },
    enabledFirst() {
      return this.currentPageIndex !== 0;
    },
    enabledEnd() {
      const lastPageIndex = this.getLastPageIndex();

      return this.currentPageIndex !== lastPageIndex;
    },
    enabledPrevPage() {
      return this.currentPageIndex > 0;
    },
    enabledNextPage() {
      const lastPageIndex = this.getLastPageIndex();

      return this.currentPageIndex < lastPageIndex;
    },
    enabledPrevGroup() {
      const paginationGroupIndex = this.getPaginationGroupIndex(this.currentPageIndex);

      return paginationGroupIndex > 0;
    },
    enabledNextGroup() {
      const currentGroupIndex = this.getPaginationGroupIndex(this.currentPageIndex);

      const lastPageIndex = this.getLastPageIndex();
      const lastPageGroupIndex = this.getPaginationGroupIndex(lastPageIndex);

      return currentGroupIndex < lastPageGroupIndex;
    },
    computedScrollWidth() {
      let ret = "";
      if (!this.isHorizontalScroll) {
        ret = "";
      } else {
      }
      return ret;
    },
    cssProps() {
      return {
        "--scrollWidth": this.scrollWidth,
        "--isHorizontalScroll": this.isHorizontalScroll,
      };
    },
  },

  methods: {
    getPaginationGroupIndex(pageIndex) {
      const result = Math.floor(pageIndex / this.maxPaginationCount);
      return result;
    },
    getEndPageIndex(startPageIndex) {
      // if( this.totalItemCount < 0 ) return -1;

      if (this.totalPageCount < 1) return 0;

      const lastPageIndex = this.getLastPageIndex();

      let maxPageIndex = startPageIndex + this.maxPaginationCount - 1;

      const result = Math.min(lastPageIndex, maxPageIndex);

      return result;
    },
    getLastPageIndex() {
      // if( this.totalItemCount < 0 ) return 0;
      if (this.totalPageCount < 1) return 0;

      const result = this.totalPageCount - 1;

      // const result = Math.floor( ( this.totalItemCount - 1 ) / this.maxRowCount );
      return result;
    },
    onChangePage(pageIndex) {
      this.$emit("onChangePage", pageIndex);
    },
    onClickPageNum(pageIndex) {
      this.onChangePage(pageIndex);
    },
    onClickEndPage() {
      const lastPageIndex = this.getLastPageIndex();

      if (lastPageIndex < 0) {
        return;
      }
      this.onChangePage(lastPageIndex);
    },
    onClickPrevGroup() {
      const paginationGroupIndex = this.getPaginationGroupIndex(this.currentPageIndex);
      const toGroupIndex = paginationGroupIndex - 1;

      this.onChangePage(toGroupIndex * this.maxPaginationCount);
    },
    onClickNextGroup() {
      const paginationGroupIndex = this.getPaginationGroupIndex(this.currentPageIndex);
      const toGroupIndex = paginationGroupIndex + 1;

      this.onChangePage(toGroupIndex * this.maxPaginationCount);
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal_scroll {
  overflow-x: auto;
  & > table {
    width: var(--scrollWidth);
  }
  @media screen and( max-width :1279px ) {
    overflow-x: visible;
  }
}
</style>
