<template>
  <CategoryGroup
    :kindList0="kindList0"
    :kindList1="kindList1"
    :kindList2="kindList2"
    :category0="category0"
    :category1="category1"
    :category2="category2"
    @onChangedKind0="onChangedKind0"
    @onChangedKind1="onChangedKind1"
    @onChangedKind2="onChangedKind2"
  />
</template>

<script>
import CategoryGroup from "@/components/common/selectBox/CategoryGroup";
import { CATEGORY_ACTION } from "@/store/modules/category/action";

export default {
  name: "CategoryGroupIncludeData",
  components: {
    CategoryGroup,
  },
  props: {
    category0: [Number, null],
    category1: [Number, null],
    category2: [Number, null],
  },
  // mounted() {
  //   this.$store.dispatch( CATEGORY_ACTION.GET_LIST_0 );
  // },
  computed: {
    kindList0() {
      const arr = this.$store.state.category.categoryList0;

      // 최상위 cateNum 은 0.
      const cateNum = 0;

      const itemCate = arr.find((item) => item.parentCateNum === cateNum);
      if (!itemCate) return [];

      return itemCate.dataList;
    },
    kindList1() {
      if (!this.category0) return [];

      const arr = this.$store.state.category.categoryList1;

      const cateNum = this.category0;

      const itemCate = arr.find((item) => item.parentCateNum === cateNum);
      if (!itemCate) return [];

      return itemCate.dataList;
    },
    kindList2() {
      if (!this.category1) return [];

      const arr = this.$store.state.category.categoryList2;

      const cateNum = this.category1;

      const itemCate = arr.find((item) => item.parentCateNum === cateNum);
      if (!itemCate) return [];

      return itemCate.dataList;
    },
  },
  methods: {
    setForce(category0, category1, category2) {
      if (category0) this.onChangedKind0(category0);
      if (category1) this.onChangedKind1(category1);
      if (category2) this.onChangedKind2(category2);
    },
    onChangedKind0(newId) {
      this.$emit("update:category0", newId);

      this.$emit("update:category1", null);
      this.$emit("update:category2", null);

      this.$store.dispatch(CATEGORY_ACTION.GET_LIST_1, newId);
    },
    onChangedKind1(newId) {
      this.$emit("update:category1", newId);
      this.$emit("update:category2", null);

      this.$store.dispatch(CATEGORY_ACTION.GET_LIST_2, newId);
    },
    onChangedKind2(newId) {
      this.$emit("update:category2", newId);
    },
  },
};
</script>
