<template>
  <div class="page_comm">
    <div class="inner_page">
      <button
        type="button"
        :class="['first', 'btn_page', { disabled: !enabledPrevGroup }]"
        @click="onClickPrevGroup"
      >
        <span class="ico_purchase ico_arr_first">처음</span>
      </button>
      <button
        type="button"
        :class="['prev', 'btn_page', { disabled: !enabledPrevPage }]"
        @click="onClickPrevPage"
      >
        <span class="ico_purchase ico_arr_prev">이전</span>
      </button>
      <button
        v-for="n in showPageCount"
        :key="n"
        type="button"
        :class="[
          'paginate_button',
          selectedPageIndex === startPageIndex + n - 1 ? 'current' : 'not_current',
        ]"
        @click="onClickNum(startPageIndex + n - 1)"
      >
        {{ startPageIndex + n }}
      </button>
      <button
        type="button"
        :class="['next', 'btn_page', { disabled: !enabledNextPage }]"
        @click="onClickNextPage"
      >
        <span class="ico_purchase ico_arr_next">다음</span>
      </button>
      <button
        type="button"
        :class="['last', 'btn_page', { disabled: !enabledNextGroup }]"
        @click="onClickNextGroup"
      >
        <span class="ico_purchase ico_arr_last">마지막</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    startPageIndex: Number,
    showPageCount: Number,
    selectedPageIndex: Number,
    enabledFirst: Boolean,
    enabledEnd: Boolean,
    enabledPrevPage: Boolean,
    enabledNextPage: Boolean,
    enabledPrevGroup: Boolean,
    enabledNextGroup: Boolean,
  },
  methods: {
    dispatchPageClicked(toPageIndex) {
      this.$emit("onClickPageNum", toPageIndex);
    },
    onClickNum(pageIndex) {
      this.dispatchPageClicked(pageIndex);
    },
    onClickNextPage() {
      this.dispatchPageClicked(this.selectedPageIndex + 1);
    },
    onClickPrevPage() {
      this.dispatchPageClicked(this.selectedPageIndex - 1);
    },
    onClickFirstPage() {
      this.dispatchPageClicked(0);
    },
    onClickEndPage() {
      this.$emit("onClickEndPage");
    },
    onClickPrevGroup() {
      this.$emit("onClickPrevGroup");
    },
    onClickNextGroup() {
      this.$emit("onClickNextGroup");
    },
  },
};
</script>
<style scoped>
.page_comm {
  clear: both;
  padding-top: 25px;
  font-size: 0;
  text-align: center;
}
.page_comm .inner_page {
  display: inline-block;
  position: relative;
  padding: 0 75px;
  vertical-align: top;
}
.page_comm .btn_page {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  padding: 10px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  vertical-align: top;
  cursor: pointer;
  box-sizing: border-box;
}
.page_comm .btn_page.first {
  left: 0;
  border-radius: 2px 0 0 2px;
}
.page_comm .btn_page.prev {
  left: 29px;
}
.page_comm .btn_page.last {
  right: 0;
}
.page_comm .btn_page.next {
  right: 29px;
}
.page_comm .btn_page.disabled {
  display: none;
}
.page_comm .btn_page.disabled + .btn_page {
  border-radius: 2px;
}
.page_comm .btn_page + .btn_page {
  border-radius: 0 2px 2px 0;
}
.page_comm .paginate_button {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  font-size: 12px;
  line-height: 31px;
  background-color: #fff;
  color: #888;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
}
.page_comm .btn_page:hover,
.page_comm .not_current:hover {
  background-color: #f5f5f5;
}
.page_comm .current {
  border-color: #888;
  font-weight: 700;
  background-color: #888;
  color: #fff;
}

.page_comm .paginate_button + .paginate_button {
  margin-left: 4px;
}
.page_comm .paginate_button + .btn_page {
  border-radius: 2px 0 0 2px;
}
.page_comm .paginate_button + .btn_page + .btn_page {
  border-radius: 0 2px 2px 0;
}
.page_comm .paginate_button + .btn_page.disabled + .btn_page {
  border-radius: 2px;
}
</style>
