import { ALERT_ACTION } from "@/store/modules/alert/action";
import { getDateString } from "@/utils/dateUtils";
import {
  getCategoryDepthText,
  getAddressTextFull,
  getCountWithCurrency,
  getCountWithQuantity,
} from "@/utils/spUtils";

const ListLineMixin = {
  props: {
    rowData: Object,
  },
  computed: {
    regDate() {
      if (!this.rowData || !this.rowData.regDate) return "";
      return getDateString(this.rowData.regDate);
    },
    startDate() {
      if (!this.rowData || !this.rowData.startDate) return "";
      return getDateString(this.rowData.startDate);
    },
    endDate() {
      if (!this.rowData || !this.rowData.endDate) return "";
      return getDateString(this.rowData.endDate);
    },
    lastDate() {
      if (!this.rowData || !this.rowData.lastDate) return "";
      return getDateString(this.rowData.lastDate);
    },
    statusDate() {
      if (!this.rowData || !this.rowData.statusDate) return "";
      return getDateString(this.rowData.statusDate);
    },
    orderDate() {
      if (!this.rowData || !this.rowData.orderDate) return "";
      return getDateString(this.rowData.orderDate);
    },
    agreeDate() {
      if (!this.rowData || !this.rowData.agreeDate) return "";
      return getDateString(this.rowData.agreeDate);
    },
    managerName() {
      const ret = this.$options.filters.idWithNameByObj(this.rowData);
      return ret;
    },
    // 물품,하드웨어,서버 -> 물품>하드웨어>서버
    category() {
      if (!this.rowData || !this.rowData.category) return "";
      return getCategoryDepthText(this.rowData.category);
    },
    addressTextFull() {
      if (!this.rowData) return "";

      return getAddressTextFull(this.rowData.address, this.rowData.address2);
    },
    priceWithCurrency() {
      return getCountWithCurrency(this.rowData.price, this.rowData.currency);
    },
  },
  methods: {
    alert(strMess, cb) {
      if (!strMess) {
        return;
      }

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: strMess,
        onClickY: cb,
      });
    },
    getCountWithCurrency(count, value) {
      return getCountWithCurrency(count, value);
    },
    getCountWithQuantity(count, value) {
      return getCountWithQuantity(count, value);
    },
  },
};
export default ListLineMixin;
