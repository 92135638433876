<template>
  <SelectBox class="w90" :dataList="pageRange" :value.sync="currentMaxRowCountSync" />
</template>

<script>
import SelectBox from "./SelectBox";
import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";

export default {
  name: "SelectBoxPageRange",
  components: {
    SelectBox,
  },
  props: {
    currentMaxRowCount: Number,
  },
  computed: {
    ...mapState({
      pageRange: (state) => state.commonCode.pageRange,
    }),
    currentMaxRowCountSync: {
      get() {
        return this.currentMaxRowCount;
      },
      set(value) {
        this.$emit("update:currentMaxRowCount", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch(COMMON_CODE_ACTION.GET_PAGE_RANGE);
  },
};
</script>
