<template>
  <CheckboxBase>
    <input
      :id="id"
      ref="checkbox"
      v-model="input"
      type="checkbox"
      class="checkBoxBase"
      :value="id"
      :disabled="isDisabled"
      @change="onChange"
    />
    <label :class="{ ir_pm: text == undefined }" :for="id"
      ><span class="ico_purchase ico_checkbox" />{{ text }}</label
    >
  </CheckboxBase>
</template>

<script>
import CheckboxBase from "./CheckboxBase";

export default {
  name: "CheckboxItem",
  components: {
    CheckboxBase,
  },
  props: {
    id: [String, Number, Object],
    text: String,
    checkedNames: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
  methods: {
    onChange(val) {
      this.$emit("onChange", val);
    },
    forceUnChecked() {
      this.$refs.checkbox.checked = false;
    },
    forceChecked() {
      this.$refs.checkbox.checked = true;
    },
  },
};
</script>
