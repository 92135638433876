<template>
  <div class="area_filter">
    <div class="box_filter">
      <slot />
    </div>
    <div v-if="btnBasic" class="group_btn">
      <button type="submit" :class="[submitBtnSize, submitBtnType]" @click="onClickSearch">
        {{ submitBtnText }}
      </button>
      <button
        v-if="excelBtn"
        type="button"
        :class="['btn_secondary', submitBtnSize]"
        @click="onClickExcelBtn"
      >
        엑셀 다운로드
      </button>
      <button
        v-if="templateAddBtn"
        type="button"
        :class="['btn_tertiary', submitBtnSize]"
        @click="onClickTemplateAdd"
      >
        <span class="ico_purchase ico_cross_w" />템플릿 등록
      </button>
      <button type="button" :class="['btn_reset', 'btn_small']" @click="onClickReset">
        초기화
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterBarBase",
  props: {
    btnBasic: {
      type: Boolean,
      default: true,
    },
    excelBtn: {
      type: Boolean,
      default: false,
    },
    templateAddBtn: {
      type: Boolean,
      default: false,
    },
    resetBtn: {
      type: Boolean,
      default: false,
    },
    btnSize: String,
    btnType: String,
    btnText: String,
  },
  computed: {
    submitBtnSize() {
      if (this.btnSize) {
        return "btn_" + this.btnSize;
      } else {
        return "btn_large";
      }
    },
    submitBtnType() {
      if (this.btnType) {
        return "btn_" + this.btnType;
      } else {
        return "btn_primary";
      }
    },
    submitBtnText() {
      if (this.btnText) {
        return this.btnText;
      } else {
        return "검색";
      }
    },
  },
  methods: {
    onClickSearch() {
      this.$emit("onClickSearch");
    },
    onClickExcelBtn() {
      this.$emit("onClickExcelBtn");
    },
    onClickTemplateAdd() {
      this.$emit("onClickTemplateAdd");
    },
    onClickReset() {
      this.$emit("onClickReset");
    },
  },
};
</script>

<style lang="scss" scoped>
.box_filter {
  display: table;
  width: 100%;
  border-top: 2px solid #222;
  border-bottom: 1px solid #d8d8d8;
}
/* .box_filter:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''} */
.box_filter::v-deep dl {
  display: table-cell;
  position: relative;
  padding: 8px 48px 16px 32px;
  padding-left: 152px;
  vertical-align: top;
}
.box_filter::v-deep dl:only-child {
  padding-right: 32px;
}
.box_filter::v-deep dl:first-child dt:first-child + dd {
  display: inline-block;
  vertical-align: top;
}
.box_filter::v-deep dl + dl {
  padding: 8px 32px 16px 48px;
  padding-left: 168px;
  border-left: 1px solid #e1e1e1;
}
.box_filter::v-deep dl + dl dt {
  left: 48px;
}
.box_filter::v-deep dt {
  position: absolute;
  left: 32px;
  width: 104px;
  height: 32px;
  padding: 8px 8px 0 0;
  font-size: 12px;
  line-height: 32px;
  color: #222;
}
.box_filter::v-deep dd {
  padding-top: 8px;
}
.box_filter::v-deep dd > .check_comm,
.box_filter::v-deep dd > .radio_comm {
  padding-top: 7px;
  padding-bottom: 7px;
}
.box_filter::v-deep dd .group_form .group_radio + .tf_comm {
  margin-left: 32px;
}
.box_filter::v-deep .row_pack .group_radio + .tf_comm,
.box_filter::v-deep .row_pack .group_radio + .area_search {
  margin-left: 32px;
}
.box_filter::v-deep .row_pack + .row_pack {
  margin-top: 16px;
}
.box_filter > .group_form {
  padding: 17px 24px;
}
.box_filter::v-deep .group_form .radio_comm + .tf_comm,
.box_filter::v-deep .group_form .radio_comm + .area_search {
  margin-left: 32px;
}
.box_filter::v-deep .group_form + .group_form {
  margin-top: 16px;
}
.box_filter + .group_btn {
  padding: 12px 0 24px;
  text-align: center;
}
.box_filter + .group_btn .btn_large {
  width: 120px;
  padding-left: 0;
  padding-right: 0;
}
.box_filter + .group_btn button + button {
  margin-left: 8px;
}
.box_filter + .group_btn button + .btn_reset {
  margin-top: 4px;
}
.box_filter:only-child {
  margin-bottom: 32px;
}
/* 상세 페이지 내 필터바 */
.area_view .area_filter .box_filter {
  border-top: 1px solid #222;
}
.area_view .area_filter .box_filter dl {
  padding: 8px 32px 24px 152px;
}
.area_view .area_filter + .tbl_comm {
  margin-top: 32px;
  border-top: 1px solid #e1e1e1;
}
</style>
