<template>
  <div class="calendar_comm" :data-tooltip="toolTip">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CalendarBase",
  props: {
    toolTip: String,
  },
};
</script>

<style scoped>
.calendar_comm {
  display: inline-block;
  position: relative;
  height: 32px;
  vertical-align: top;
}
.calendar_comm::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 0;
  bottom: calc(100% + 5px);
  display: none;
  padding: 1px 8px 2px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.4;
  background-color: #888;
  color: #fff;
  white-space: nowrap;
}
.calendar_comm.is-active::after {
  display: inline-block;
}
/* 인풋박스_영역 */
.calendar_comm /deep/ .el-date-editor.el-input,
.calendar_comm /deep/ .el-date-editor.el-input__inner {
  width: auto;
}
.calendar_comm /deep/ .el-date-editor.el-date-editor--daterange {
  width: 327px;
  padding: 0;
  border: 0 none;
}
/* 인풋박스 */
.calendar_comm /deep/ .el-input__inner,
.calendar_comm /deep/ .el-range-input {
  display: inline-block;
  width: 152px;
  height: 32px;
  padding: 0 34px 0 11px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  font-size: 12px;
  line-height: 32px;
  color: #555;
  text-align: left;
  box-sizing: border-box;
  vertical-align: top;
}
.calendar_comm /deep/ .el-date-editor--datetime .el-input__inner {
  width: 168px;
}
/* 인풋박스_활성화 */
.calendar_comm /deep/ .el-input.is-active .el-input__inner,
.calendar_comm /deep/ .el-input__inner:focus {
  border-color: #555;
}
/* 인풋박스_disabled */
.calendar_comm /deep/ .is-disabled .el-input__inner,
.calendar_comm /deep/ .is-disabled .el-range-input {
  border-color: #f0f0f0;
  background-color: #fbfbfb;
  color: #bbb;
}
.calendar_comm /deep/ .el-range-editor.is-disabled {
  background: none;
}
/* 아이콘 */
.calendar_comm /deep/ .el-input__prefix,
.calendar_comm /deep/ .el-input__icon {
  display: none;
}
.calendar_comm /deep/ .ico_calendar {
  position: absolute;
  top: 9px;
  right: 12px;
}
.calendar_comm /deep/ .el-date-editor--daterange + .ico_calendar {
  left: 130px;
  right: auto;
}
.calendar_comm /deep/ .el-date-editor--daterange + .ico_calendar + .ico_calendar {
  left: 305px;
  right: auto;
}
.calendar_comm /deep/ .el-range-separator {
  width: 23px;
  padding: 0;
  font-size: 12px;
  color: #555;
  text-align: center;
}
.calendar_comm.w100p /deep/ .el-input__inner {
  width: 100%;
}
</style>
