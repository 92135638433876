import ApiService from "@/services/ApiService";

import { ALERT_ACTION } from "@/store/modules/alert/action";
import { board } from "@/constants/options";

import { makeQueryStringByObject } from "@/utils/urlUtils";
import { stringToDate } from "@/utils/dateUtils";
import { ONE_WEEK, YESTERDAY, ALL } from "@/constants/dates";

import ListPageExcelMixin from "./ListPageExcelMixin";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

const ListPageMixin = {
  mixins: [ListPageExcelMixin],
  data() {
    return {
      dataList: [],
      size: board.DEFAULT_MAX_ROW_COUNT, // 한번에 불러오는 아이템 개수.

      totalPageCount: 0,
      currentPageIndex: 0,

      defaultKeywordSelectedId: "title",
      defaultKeywordInputText: "",
      defaultDates: [],
      defaultStateId: "0",

      defaultDateId: ONE_WEEK,
      cateNum: null,
    };
  },
  computed: {
    maxPaginationCount() {
      return board.MAX_PAGINATION_COUNT;
    },
    defaultRowCount() {
      return board.DEFAULT_MAX_ROW_COUNT;
    },
  },
  methods: {
    getDataInit(params) {
      this.getData(params);
    },
    async getData(params) {
      // 특정 페이지에만 들어가는 값이 있다.
      // ex) 공지사항 페이지 - params.searchType = "M";
      await this.$store.dispatch(PROGRESS_START_ACTION);

      if (this.ADD_PARAMS) {
        params = Object.assign(params, this.ADD_PARAMS);
      }

      const urlRest = makeQueryStringByObject(params);
      const url = this.API_PATH + urlRest;

      const result = await ApiService.shared.getData(url);

      await this.$store.dispatch(PROGRESS_END_ACTION);

      const { code, data, text, total } = result;
      if (code !== "200") {
        this.alert(text ? text : code);
        return;
      }
      if (code === "200") {
        this.dataList = data;

        params.page = "1";
        params.size = this.size;
        this.totalPageCount = parseInt(total);
      } else {
        this.dataList = [];
        this.totalPageCount = 0;
      }

      this.$emit("onChangedList", this.dataList);
    },
    onChangePage(nPageIndex) {
      this.currentPageIndex = nPageIndex;

      let query = Object.assign({}, this.$route.query, {
        page: nPageIndex + 1,
        size: this.size,
      });

      this.replace(query);
    },
    async replace(query) {
      await this.$router.replace({ query: query }).catch(() => {});

      let params = this.checkUrlQuery();

      await this.getData(params);
    },
    onClickSearch(objData) {
      let params = this.getFilterbarParams(objData);

      params.page = "1";
      params.size = this.size;

      if (Object.prototype.hasOwnProperty.call(this, "checkedNames")) {
        if (this.$refs.checkAll) {
          this.$refs.checkAll.forceUnChecked();
        }
        this.checkedNames = [];
      }

      this.replace(params);
    },
    onChangeRowCount(value) {
      this.currentPageIndex = 0;
      this.size = value;

      let query = Object.assign({}, this.$route.query, {
        page: this.currentPageIndex + 1,
        size: this.size,
      });

      this.replace(query);
    },
    onClickResetFilterBar() {
      this.resetFilterCommon();

      // 초기값이 다른 경우 별도 처리.
      // ex) this.defaultKeywordSelectedId = 'title';

      this.$nextTick(() => {
        this.$refs.filterBar.resetData();
      });
    },
    resetFilterCommon() {
      this.defaultKeywordSelectedId = this.keywordList[0].id;
      this.defaultKeywordInputText = "";

      this.defaultStateId = "0";

      // 자산을 제외하고는 아무것도 입력 안했을 때 일주일 날짜를 보내달라고 함.
      // 근데 자산에서는 어제 날짜. 자산에는 전체도 있음.
      let today = new Date();
      let dateStandard = new Date(today);

      if (this.defaultDateId === YESTERDAY) {
        dateStandard.setDate(dateStandard.getDate() - 1);
        this.defaultDates = [dateStandard, today];
      } else if (this.defaultDateId === ALL) {
        this.defaultDates = [];
      } else {
        dateStandard.setDate(dateStandard.getDate() - 6);
        this.defaultDates = [dateStandard, today];
      }
    },
    checkUrlQueryCommon() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordList);

      const objDates = this.checkQueryDates(query);

      const objStatus = this.checkQueryStatus(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus);
    },
    checkQueryStatus(query) {
      const { status } = query;

      if (!status) return {};

      this.defaultStateId = status;

      return { status: status };
    },
    checkQueryDates(query, startDtKey, endDtKey) {
      const startDt = query[startDtKey];
      const endDt = query[endDtKey];

      const isValid = startDt && endDt;

      let ret = {};
      if (isValid) {
        this.defaultDates = [stringToDate(startDt), stringToDate(endDt)];

        ret[startDtKey] = startDt;
        ret[endDtKey] = endDt;
      } else {
        this.defaultDates = [];
      }

      return ret;
    },
    checkQueryPaging(query) {
      const { page, size } = query;

      const ret = {
        page: page || "1",
        // size: size || this.defaultRowCount,
        size: size || this.size || this.defaultRowCount,
      };

      this.currentPageIndex = Number.parseInt(ret.page) - 1;
      this.size = Number.parseInt(ret.size);

      return ret;
    },
    checkQueryKeywords(query, arrKeyword) {
      let ret = {};

      const itemKeyword = arrKeyword.find((item) => query[item.id]);

      if (!itemKeyword) return ret;

      const keywordId = itemKeyword.id;

      ret[keywordId] = query[keywordId];

      // 여기서 그냥 셋하겠다..
      //  this.defaultKeywordSelectedId = keywordId;
      //this.defaultKeywordInputText = query[keywordId];

      return ret;
    },
    checkQueryCateNum(query) {
      const { cateNum } = query;

      if (!cateNum) return null;

      return {
        cateNum: cateNum,
      };
    },
    alert(strMess) {
      // this.isShowAlert = true;
      // this.alertText = strMess || 'error';

      if (!strMess) {
        return;
      }
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: strMess,
      });
    },
    getExcelDownloadUrl() {
      const filterBar = this.$refs.filterBar;

      if (!filterBar) return;

      const objFilterBarData = filterBar.getCommonFilterbarData();

      const params = this.getFilterbarParams(objFilterBarData, true);

      params.page = "1";
      params.size = this.size;

      const url = this.getExcelDownloadPath(params);

      return url;
    },
    getFilterbarParamsCommon(objData) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, statusCode } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params.startDt = startDt;
        params.endDt = endDt;
      }

      if (statusCode) {
        params.statusCode = statusCode;
      }

      return params;
    },
  },
};
export default ListPageMixin;
