<template>
  <div class="area_multi">
    <BoardContractType
      :tblStyle="tblStyle"
      :isMultiMode="isMultiMode"
      :dataList="list"
      :currentPageIndex="currentPageIndex"
      :maxRowCount="maxRowCount"
      :maxPaginationCount="maxPaginationCount"
      :totalPageCount="totalPageCount"
      @onChangePage="(pageIndex) => $emit('onChangePage', pageIndex)"
    >
      <template v-slot:colgroup0>
        <slot name="colgroup0" />
      </template>
      <template v-slot:tr0>
        <slot name="tr0" />
      </template>
      <template v-slot:rows0>
        <template v-for="(item, index) in list">
          <slot name="row0" :rowData="item" :rowIndex1="index" />
        </template>
      </template>
      <template v-slot:emptyList0>
        <slot name="emptyList0" />
      </template>

      <template v-slot:colgroup1>
        <slot name="colgroup1" />
      </template>
      <template v-slot:tr1>
        <slot name="tr1" />
      </template>
      <template v-slot:rows1>
        <template v-for="(item, index) in list">
          <slot name="row1" :rowData="item" :rowIndex2="index" />
        </template>
      </template>
      <template v-slot:emptyList1>
        <slot name="emptyList1" />
      </template>
    </BoardContractType>
  </div>
</template>

<script>
import BoardContractType from "@/components/common/board/BoardContractType";

export default {
  components: {
    BoardContractType,
  },
  props: {
    tblStyle: String,
    isMultiMode: Boolean,

    list: Array,
    currentPageIndex: Number,
    totalPageCount: Number,
    maxRowCount: Number,
    maxPaginationCount: Number,
  },
};
</script>
<style scoped>
.area_multi {
  overflow: hidden;
  border-top: 1px solid #222;
}
.area_multi >>> .ico_multi {
  display: none;
  position: absolute;
  top: 50%;
  right: 4px;
  z-index: 1;
  margin-top: -6px;
}
.area_multi >>> .multi_left {
  position: relative;
  float: left;
  width: 90%;
  padding-right: 12px;
}
.area_multi >>> .multi_left .ico_multi {
  display: block;
}
.area_multi >>> .multi_left .ui-resizable-e {
  z-index: 0 !important;
  width: 10px;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 1px solid #e2e1f4;
  border-left: 1px solid #c9c7ed;
  border-bottom: 1px solid #e1e1e1;
  background-color: #f2f2f8;
  box-shadow: -5px 0 8px 0 rgba(74, 71, 165, 0.15);
}
.area_multi >>> .multi_right {
  overflow: hidden;
}
.area_multi >>> .multi_right .tbl_comm th {
  background-color: #f9fafd;
}
.area_multi >>> .multi_right .tbl_comm td {
  text-align: right;
}
.area_multi >>> .multi_right .tbl_comm tbody .tr_hover td,
.area_multi >>> .multi_right .tbl_comm tbody tr:hover td {
  background-color: #f9fafd;
}
.area_multi >>> .ui-resizable-e:hover {
  background-color: #e2e1f4;
}
.area_multi >>> .ui-resizable-s {
  display: none;
}
.area_multi >>> .tbl_comm {
  overflow-x: auto;
  overflow-y: hidden;
  border-top: 0 none;
}
.area_multi.disabled >>> .ui-resizable-e:hover {
  background-color: #f2f2f8;
  cursor: default;
}
.area_multi.disabled >>> .ico_multi {
  display: none;
}
</style>
