import queryString from "query-string";

export function makeQueryStringByObject(obj) {
  const str = `?${Object.keys(obj)
    .reduce((a, k) => {
      a.push(`${k}=${encodeURIComponent(obj[k])}`);
      return a;
    }, [])
    .join("&")}`;
  return str;
}

export function makePathWithQuery(apiPath, id, query) {
  const stringified = queryString.stringify(query);

  let path = `${apiPath}/${id}`;
  if (stringified) {
    path += `?${stringified}`;
  }

  return path;
}

export function makePathWithPath(apiPath, id, query) {
  const stringified = queryString.stringify(query);

  if (stringified) {
    path += `?${stringified}`;
  }

  let path = `${apiPath}?docNo=${id}`;

  return path;
}
