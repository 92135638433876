<template>
  <div class="check_comm">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CheckboxBase",
};
</script>

<style scoped>
.check_comm {
  display: inline-block;
  position: relative;
  min-width: 16px;
  min-height: 18px;
  vertical-align: top;
}
.check_comm input:disabled,
.check_comm input:disabled:checked {
  cursor: auto;
}
.check_comm input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 0 none;
  opacity: 0.01;
  filter: alpha(opacity=1);
  cursor: pointer;
}
.check_comm label {
  display: block;
  padding-left: 24px;
  font-size: 12px;
  line-height: 18px;
  color: #555;
}
.check_comm .ir_pm {
  padding-left: 0;
}
.check_comm .ico_checkbox {
  position: absolute;
  top: 1px;
  bottom: 0;
  left: 0;
  margin: auto;
}
.check_comm + .check_comm {
  margin-left: 32px;
}
</style>
