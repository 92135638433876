import { make00 } from "./stringUtils";

// 20190621180922 -> 2019-06-22
// 이 형태만 고려한 메서드이다.
export function getDateString(yyyymmdd, div = "-") {
  if (!yyyymmdd || yyyymmdd.length < 8) return "";

  const year = yyyymmdd.slice(0, 4);
  const month = yyyymmdd.slice(4, 6);
  const date = yyyymmdd.slice(6, 8);

  const ret = `${year}${div}${month}${div}${date}`;

  return ret;
}

// 20190621180922 -> 2019-06-22 09:22
// 이 형태만 고려한 메서드이다.
export function getDateStringWithTime(yyyymmddhhmm) {
  if (yyyymmddhhmm.length < 8) return "";

  const year = yyyymmddhhmm.slice(0, 4);
  const month = yyyymmddhhmm.slice(4, 6);
  const date = yyyymmddhhmm.slice(6, 8);

  const hour = yyyymmddhhmm.slice(8, 10);
  const minute = yyyymmddhhmm.slice(10, 12);

  const ret = `${year}-${month}-${date} ${hour}:${minute}`;

  return ret;
}

export function dateToString(dateTarget) {
  if (!dateTarget) {
    return;
  }
  const year = dateTarget.getFullYear();
  let month = dateTarget.getMonth() + 1;
  let date = dateTarget.getDate();

  month = make00(month);
  date = make00(date);

  const ret = `${year}${month}${date}`;

  return ret;
}

export function dateToyymmdd(dateTarget) {
  if (!dateTarget) {
    return;
  }
  let year = dateTarget.getFullYear();
  let month = dateTarget.getMonth() + 1;
  let date = dateTarget.getDate();

  year = String(year).slice(-2);
  month = make00(month);
  date = make00(date);

  const ret = `${year}${month}${date}`;

  return ret;
}

export function dateToStringWithTime(dateTarget) {
  const year = dateTarget.getFullYear();
  let month = dateTarget.getMonth() + 1;
  let date = dateTarget.getDate();

  let hour = dateTarget.getHours();
  let minute = dateTarget.getMinutes();
  let second = dateTarget.getSeconds();

  month = make00(month);
  date = make00(date);
  hour = make00(hour);
  minute = make00(minute);
  second = make00(second);

  const ret = `${year}${month}${date}${hour}${minute}${second}`;

  return ret;
}

export function dateToStringHasDiv(dateTarget, strDiv = "-") {
  const year = dateTarget.getFullYear();
  let month = dateTarget.getMonth() + 1;
  let date = dateTarget.getDate();

  month = make00(month);
  date = make00(date);

  const ret = `${year}${strDiv}${month}${strDiv}${date}`;

  return ret;
}

export function dateToStringWithTimeHasDiv(dateTarget) {
  const year = dateTarget.getFullYear();
  let month = dateTarget.getMonth() + 1;
  let date = dateTarget.getDate();

  let hour = dateTarget.getHours();
  let minute = dateTarget.getMinutes();
  let second = dateTarget.getSeconds();

  month = make00(month);
  date = make00(date);
  hour = make00(hour);
  minute = make00(minute);
  second = make00(second);

  const ret = `${year}-${month}-${date} ${hour}:${minute}`;

  return ret;
}

export function stringToDate(yyyymmdd) {
  if (!yyyymmdd) {
    return;
  }
  if (yyyymmdd.length > 8) {
    return stringToDateWithTime(yyyymmdd);
  }

  const year = yyyymmdd.slice(0, 4);
  const month = yyyymmdd.slice(4, 6);
  const date = yyyymmdd.slice(6, 8);

  const nMonth = Number.parseInt(month, 10) - 1;

  const retDate = new Date(year, nMonth, date);

  return retDate;
}

export function stringToDateWithTime(yyyymmddhhmmss) {
  if (yyyymmddhhmmss.length < 14) {
    return stringToDate(yyyymmddhhmmss);
  }

  const year = yyyymmddhhmmss.slice(0, 4);
  const month = yyyymmddhhmmss.slice(4, 6);
  const date = yyyymmddhhmmss.slice(6, 8);

  const hour = yyyymmddhhmmss.slice(8, 10);
  const minute = yyyymmddhhmmss.slice(10, 12);
  const second = yyyymmddhhmmss.slice(12, 14);

  const nMonth = Number.parseInt(month, 10) - 1;

  const retDate = new Date(year, nMonth, date, hour, minute, second);

  return retDate;
}

export function getLastDateOfMonth(year, month) {
  const date = new Date(year, month, 0);

  return date.getDate();
}

export function getDate(month, date) {
  const now = new Date();

  now.setMonth(month - 1);

  const lastDateOfMonth = getLastDateOfMonth(now.getFullYear(), month);

  const minDate = Math.min(date, lastDateOfMonth);

  now.setDate(minDate);

  return now;
}

export function getFormatDataWithOutDash(date) {
  const year = date.getFullYear();
  let month = 1 + date.getMonth();
  month = month >= 10 ? month : "0" + month;

  let day = date.getDate();
  day = day >= 10 ? day : "0" + day;
  return year + month + day;
}

export function firstDayOfCurrentMonthWithOutDash() {
  var today = new Date();
  var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  return getFormatDataWithOutDash(firstDayOfMonth);
}

export function lastDayOfCurrentMonthWithOutDash() {
  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return getFormatDataWithOutDash(lastDayOfMonth);
}

export function getMaxDates(date) {
  const year = date.getFullYear();
  let month = 1 + date.getMonth();
  month = month >= 10 ? month : "0" + month;

  // return  year + 5 + '-' + month + '-' + '01';
  return year + 5 + "-" + (month - 1);
}

export function getLastDayOfNextMonth() {
  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

  return lastDayOfMonth;
}

export function getFormatData(date) {
  const year = date.getFullYear();
  let month = 1 + date.getMonth();
  month = month >= 10 ? month : "0" + month;

  let day = date.getDate();
  day = day >= 10 ? day : "0" + day;
  return year + "-" + month + "-" + day;
}

export function getDayToStringWithoutTime(date) {
  const day = dateToStringWithTime(date);
  return day.slice(0, 8);
}

export function getDateRange(range) {
  let dateStandard;
  const today = new Date();

  if (!range) return;

  switch (range) {
    case "ONE_WEEK": {
      dateStandard = new Date(today);
      dateStandard.setDate(dateStandard.getDate() - 7);

      break;
    }
    case "ONE_MONTH": {
      dateStandard = new Date(today);
      dateStandard.setMonth(dateStandard.getMonth() - 1);

      break;
    }
    case "TWO_MONTH": {
      dateStandard = new Date(today);
      dateStandard.setMonth(dateStandard.getMonth() - 2);

      break;
    }
    case "SIX_MONTH": {
      dateStandard = new Date(today);
      dateStandard.setMonth(dateStandard.getMonth() - 6);

      break;
    }
  }

  return dateStandard;
}

export function getAccountDates() {
  const date = new Date();
  const year = date.getFullYear();
  let month = 1 + date.getMonth();
  month = month >= 10 ? month : "0" + month;

  const obj = {};
  obj.accountStartDate = year + "-" + month;
  obj.accountEndDate = year + 1 + "-" + month;

  return obj;
}

export function dateToStringWithDiv(dateTarget, strDiv = "-") {
  const year = dateTarget.getFullYear();
  let month = dateTarget.getMonth() + 1;
  let date = dateTarget.getDate();

  month = make00(month);
  date = make00(date);

  const ret = `${year}${strDiv}${month}${strDiv}${date}`;

  return ret;
}
