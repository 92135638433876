<template>
  <tr @mouseover="mouseOverTr">
    <!-- 계약상태 -->
    <td>{{ rowData.contractStatus || "" }}</td>
    <!-- 계약구분 -->
    <td :class="{ td_ellip: rowData.contractDiv || '' }" :data-ellip="rowData.contractDiv || ''">
      {{ rowData.contractDiv || "" }}
    </td>
    <!-- 분류 -->
    <td class="td_ellip align_left" :data-ellip="category">
      {{ category }}
    </td>
    <!-- 구매요청자 -->
    <td
      :class="[{ td_ellip: rowData.contractRequester || '' }, 'align_left']"
      :data-ellip="rowData.contractRequester || ''"
    >
      {{ rowData.contractRequester || "" }}
    </td>
    <!-- 구매담당자 -->
    <td
      :class="[{ td_ellip: rowData.purchaseManager || '' }, 'align_left']"
      :data-ellip="rowData.purchaseManager || ''"
    >
      {{ rowData.purchaseManager || "" }}
    </td>
    <!-- 계약명 -->
    <td class="td_ellip">
      <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.description || ''"
        v-html="rowData.description || ''"
      />
      <!-- <a
        :class="['link_subject']"
        @click="onClickDetailLink(toPath)"
        :data-ellip="rowData.description || ''"
        v-html="rowData.description || ''"
      ></a> -->
    </td>
    <!-- 파트너사 -->
    <td class="td_ellip align_left" :data-ellip="rowData.companyName || ''">
      {{ rowData.companyName || "" }}
    </td>
    <!-- 계약시작일 -->
    <td>{{ contractStartDate }}</td>
    <!-- 계약종료일 -->
    <td>{{ contractEndDate }}</td>
    <!-- 화페 -->
    <td>{{ rowData.currencyCode }}</td>
    <!-- 계약금액 -->
    <td class="align_right">
      {{ finalPrice }}
    </td>
    <!-- 금번정산금액 -->
    <td class="align_right">
      {{ inspectPrice }}
    </td>
    <!-- 검수담당자 -->
    <td class="td_ellip align_left" :data-ellip="rowData.inspectManager">
      {{ rowData.inspectManager }}
    </td>
    <!-- 총 정산금액 -->
    <td class="align_right">
      {{ calculatePriceSum }}
    </td>
    <!-- 정산율(%) -->
    <td class="align_right">
      {{ rowData.calculatePriceRate || "" }}
    </td>
    <!-- 파트너사 검수 -->
    <!-- <td></td> -->
    <!-- 내부 검수 -->
    <td>{{ rowData.calculateState || "" }}</td>
  </tr>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";

import { getDateString } from "@/utils/dateUtils";
import { makePathWithQuery } from "@/utils/urlUtils";
import { addCommaForMoney } from "@/utils/stringUtils";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "ContractInfoListLineInspect",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  computed: {
    toPath() {
      const { contractId } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.CONTRACT_VIEW, contractId, this.$route.query);
      return path;
    },
    contractStartDate() {
      const { contractStartDate } = this.rowData;
      if (!contractStartDate) return "";

      return getDateString(contractStartDate);
    },
    contractEndDate() {
      const { contractEndDate } = this.rowData;
      if (!contractEndDate) return "";

      return getDateString(contractEndDate);
    },
    finalPrice() {
      const { finalPrice } = this.rowData;
      if (!finalPrice) return "";

      return addCommaForMoney(finalPrice);
    },
    cutOffPrice() {
      const { cutOffPrice } = this.rowData;
      if (!cutOffPrice) return "";

      return addCommaForMoney(cutOffPrice);
      // return cutOffPrice;
    },
    inspectPrice() {
      const { inspectPrice } = this.rowData;
      if (!inspectPrice) return "";

      return addCommaForMoney(inspectPrice);
    },
    calculatePriceSum() {
      const { calculatePriceSum } = this.rowData;
      if (!calculatePriceSum) return "";

      return addCommaForMoney(calculatePriceSum);
    },
    category() {
      const { cate1, cate2, cate3 } = this.rowData;

      let category = "";

      let cateArray = [];
      if (cate1) cateArray.push(cate1);
      if (cate2) cateArray.push(cate2);
      if (cate3) cateArray.push(cate3);

      if (cateArray.length > 0) {
        if (cateArray.length == 1) {
          category = cateArray[0];
        } else {
          if (cate3) category += cate3;

          if (cate2) {
            if (cate3) {
              category += "> " + cate2;
            } else {
              category += cate2;
            }
          }

          if (cate1) {
            if (category != "") {
              category += "> " + cate1;
            } else {
              category += cate2;
            }
          }
        }
      }

      return category;
    },
    // vat() {
    //   const { vat } = this.rowData;
    //   if( vat && vat == '포함') return '포함';
    //   // 기존 데이터는 별도로 표시
    //   return '별도';
    // }
  },
  methods: {
    mouseOverTr() {
      this.$emit("mouseOverTr");
    },
  },
};
</script>
