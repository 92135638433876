<template>
  <div class="group_radio">
    <RadioItem
      v-for="(item, index) in dataList"
      :id="item.id"
      :key="index"
      :class="itemClass"
      :text="item.text"
      :name="name"
      :isDisabled="isDisabled || item.isDisabled"
      :selectedId.sync="selectedIdSync"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import RadioItem from "./RadioItem";

export default {
  name: "Radio",
  components: {
    RadioItem,
  },
  props: {
    dataList: Array,
    itemClass: String,
    name: String,
    // selectedId: String,
    selectedId: undefined,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedIdSync: {
      get() {
        return this.selectedId;
      },
      set(value) {
        this.$emit("update:selectedId", value);
      },
    },
  },
  methods: {
    onChange(to, from) {
      this.$emit("onChange", to, from);
    },
  },
};
</script>
<style scoped>
.group_radio {
  display: inline-block;
}
.group_radio .radio_comm {
  padding: 7px 0;
}
</style>
