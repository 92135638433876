<template>
  <tr @mouseover="mouseOverTr">
    <td>{{ getValue(0) }}</td>
    <td v-if="settelType > 1">
      {{ getValue(1) }}
    </td>
    <td v-if="settelType > 1">
      {{ getValue(2) }}
    </td>
    <td v-if="settelType > 2">
      {{ getValue(3) }}
    </td>
    <td v-if="settelType > 2">
      {{ getValue(4) }}
    </td>
    <td v-if="settelType > 2">
      {{ getValue(5) }}
    </td>
    <td v-if="settelType > 3">
      {{ getValue(6) }}
    </td>
    <td v-if="settelType > 3">
      {{ getValue(7) }}
    </td>
    <td v-if="settelType > 3">
      {{ getValue(8) }}
    </td>
    <td v-if="settelType > 3">
      {{ getValue(9) }}
    </td>
    <td v-if="settelType > 3">
      {{ getValue(10) }}
    </td>
    <td v-if="settelType > 3">
      {{ getValue(11) }}
    </td>
  </tr>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";

import { make00, addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "ContractInfoListLine2",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
    settelType: Number,
  },
  methods: {
    getValue(nSeq) {
      const paramName = this.getParamName(nSeq, this.settelType);

      const value = this.rowData[paramName];
      if (!value) return "0";

      return addCommaForMoney(value);
    },
    getParamName(nSeq, settelType) {
      // let num = 0;

      // switch( settelType ) {
      //   case 1: num = 1;  break;  // 당월 m01
      //   case 2: num = 3 - nSeq; break;  // 3개월 m03 m02 m01
      //   case 3: num = 6 - nSeq; break;  // 6개월 m06 ~ m01
      //   case 4: num = nSeq + 1; break;  // 12개월 m01 ~ m12
      // }

      const num = nSeq + 1;

      return `m${make00(num)}`;
    },
    mouseOverTr() {
      this.$emit("mouseOverTr");
    },
  },
};
</script>
