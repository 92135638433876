<template>
  <div class="tbl_comm tbl_view">
    <table>
      <caption class="ir_caption">
        {{
          title
        }}
      </caption>
      <colgroup>
        <slot name="colgroup">
          <col style="width: 192px" />
          <col style="width: 428px" />
          <col style="width: 192px" />
          <col style="width: 428px" />
        </slot>
      </colgroup>
      <thead v-if="hasHeader">
        <slot name="header" />
      </thead>

      <tbody>
        <slot name="body" />
      </tbody>
      <slot name="custom" />
    </table>
  </div>
</template>

<script>
export default {
  name: "TableView",
  props: {
    title: String,
  },
  computed: {
    hasHeader() {
      return this.$slots["header"];
    },
    hasFoot() {
      return this.$slots["foot"];
    },
  },
};
</script>

<style lang="scss" scoped>
/* 뷰 table */
.tbl_view::v-deep tbody tr {
  height: 1px;
}
.tbl_view::v-deep tbody th {
  height: inherit;
  padding: 8px 8px;
  border: 1px solid #e1e1e1;
  border-top: 0 none;
  font-weight: normal;
  font-size: 12px;
  background-color: #fbfbfb;
  color: #222;
  text-align: left;
  vertical-align: middle;
}
.tbl_view::v-deep tbody th:first-child {
  border-left: 0 none;
}
.tbl_view::v-deep tbody th .txt_asterisk {
  display: inline-block;
  padding-left: 4px;
  color: #f8626c;
  vertical-align: top;
}
.tbl_view::v-deep tbody td {
  height: inherit;
  padding: 8px 8px;
  border-bottom: 1px solid #e1e1e1;
  vertical-align: middle;
}

.tbl_view::v-deep tfoot td:first-child {
}
.tbl_view.tbl_view_sm12::v-deep tbody td {
  padding: 4px 6px;
}
.tbl_view::v-deep tbody td + td {
  border-left: 1px solid #e1e1e1;
}
.tbl_view::v-deep tbody td:last-child {
  padding: 8px 8px;
}
.tbl_view::v-deep tbody td > .check_comm,
.tbl_view::v-deep tbody td > .radio_comm {
  padding: 4px 0;
}
.tbl_view::v-deep tbody td .file_view {
  margin: 8px -24px -8px 0;
}
.tbl_view::v-deep tbody td .file_uploaded {
  height: auto;
  margin: 0;
  padding: 0;
}
/* .tbl_view::v-deep tbody td .connect_edit{margin:-8px -24px -8px 0}
.tbl_view::v-deep tbody td .connect_edit .list_connect{padding:13px 23px 13px 0} */
.tbl_view::v-deep tbody td .textarea_comm {
  box-sizing: border-box;
  height: 100%;
}
.tbl_view::v-deep tbody td a,
.tbl_view::v-deep tbody td .link_subject {
  display: block;
  overflow: hidden;
  padding: 6px 0;
  font-size: 12px;
  line-height: 20px;
  color: #5551ce;
  text-align: left;
  text-decoration: underline;
}
.tbl_view::v-deep .td_text {
  word-break: break-all;
}
.tbl_view::v-deep .td_text .link_subject {
  padding: 0;
}
.tbl_view::v-deep .td_text h1,
.tbl_view::v-deep .td_text strong {
  display: block;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #222;
}
.tbl_view::v-deep .td_hidden {
  background: url("~@/assets/images/bg_pattern.png");
  color: #bbb;
  text-align: center;
  vertical-align: middle;
}
.tbl_view::v-deep tbody td.no_padding {
  padding: 0;
}
.tbl_view::v-deep .td_full {
  padding: 24px;
  line-height: 18px;
}
.tbl_view::v-deep .td_preline {
  white-space: pre-line;
}
</style>
