<template>
  <tr>
    <slot />
  </tr>
</template>

<script>
export default {
  name: "ListRow",
};
</script>
