<template>
  <div class="group_form">
    <CalendarBase>
      <el-date-picker
        v-model="input"
        type="daterange"
        :range-separator="separator"
        :start-placeholder="placeholderStart"
        :end-placeholder="placeholderEnd"
        :disabled="disabled"
      />
      <span class="ico_purchase ico_calendar" />
      <span class="ico_purchase ico_calendar" />
    </CalendarBase>
    <button type="button" class="btn_fourthly btn_small" @click="onClickRangeBtn('1', 'week')">
      1주일
    </button>
    <button type="button" class="btn_fourthly btn_small" @click="onClickRangeBtn('1', 'mouth')">
      1개월
    </button>
    <button type="button" class="btn_fourthly btn_small" @click="onClickRangeBtn('2', 'mouth')">
      2개월
    </button>
    <button type="button" class="btn_fourthly btn_small" @click="onClickRangeBtn('6', 'mouth')">
      6개월
    </button>
    <slot />
  </div>
</template>

<script>
import CalendarBase from "./CalendarBase";

export default {
  name: "DateRangeQuickSelect",
  components: {
    CalendarBase,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholderStart: {
      type: String,
      default: "시작일",
    },
    placeholderEnd: {
      type: String,
      default: "종료일",
    },
    separator: {
      type: String,
      default: "~",
    },
    dates: Array,
  },
  computed: {
    input: {
      get() {
        return this.dates;
      },
      set(value) {
        this.$emit("update:dates", value);
      },
    },
  },
  methods: {
    onClickRangeBtn(rangeN, util) {
      let dateStandard;
      let value;
      const today = new Date();
      dateStandard = new Date(today);
      if (util === "week") {
        var weekToDay = Number(rangeN) * 7;
        dateStandard.setDate(dateStandard.getDate() - weekToDay);
      } else {
        dateStandard.setMonth(dateStandard.getMonth() - Number(rangeN));
      }
      value = [dateStandard, today];
      this.$emit("update:dates", value);
    },
  },
};
</script>
<style scope>
.group_form .group_form {
  display: inline-block;
  margin-left: 32px;
  vertical-align: top;
}
</style>
