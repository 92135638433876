export function downloadFile(blob, flieName) {
  // var newBlob = new Blob([blob], {type: "application/pdf"}
  const newBlob = new Blob([blob]);

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, flieName || "download");
    return;
  }

  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = data;
  link.download = flieName || "download";
  link.click();
}

export function ObjectURLFile(blob, mimeType) {
  const newBlob = new Blob([blob], { type: mimeType });
  const url = window.URL.createObjectURL(newBlob);
  return url;
}
