<template>
  <Popup popupType="tableType">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">엑셀 다운로드</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) in excelDataList" :key="index">
              <th>{{ item.name }}</th>
              <td>
                <div class="gruop_check">
                  <Checkbox
                    ref="testTarget"
                    :itemStyle="checkMaxWidth"
                    :dataList="item.fields"
                    :checkedNames.sync="checkedNamesSync"
                  />
                </div>
              </td>
            </tr>
            <tr v-if="excelRangeDataList && excelRangeDataList.length > 0">
              <th>정산정보 (기간)</th>
              <td>
                <Radio
                  name="calculateRadioData"
                  :dataList="excelRangeDataList"
                  :selectedId.sync="calculateSeletedIdSync"
                />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
      <button type="button" class="btn_secondary btn_medium" @click="onClickDownload">
        선택항목 다운로드
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import Checkbox from "@/components/common/checkbox/Checkbox";
import Radio from "@/components/common/radio/Radio";

export default {
  name: "ExcelDonwloadPopup",
  components: {
    Popup,
    TableView,
    Checkbox,
    Radio,
  },
  props: {
    excelDataList: Array,
    checkedNames: Array,
    excelRangeDataList: Array,
    calculateSeletedId: String,
  },
  data() {
    return {
      checkMaxWidth: null,
    };
  },
  computed: {
    calculateSeletedIdSync: {
      get() {
        return this.calculateSeletedId;
      },
      set(value) {
        this.$emit("update:calculateSeletedId", value);
      },
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
  watch: {
    excelDataList(newVal, oldVal) {
      if (!newVal || newVal.length === 0) return;

      this.resizeColumnWidthToMax();
    },
  },
  mounted() {
    this.resizeColumnWidthToMax();
  },
  methods: {
    resizeColumnWidthToMax() {
      this.$nextTick(function () {
        const $columns = $(".check_comm");
        const $columnWidths = $columns.map(function () {
          return $(this).width();
        });

        function getMaxOfArray(numArray) {
          return Math.max.apply(null, numArray);
        }
        const maxCilumnWidth = getMaxOfArray($columnWidths);

        if (maxCilumnWidth && maxCilumnWidth > 16) this.checkMaxWidth = `width:${maxCilumnWidth}px`;
      });
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickDownload() {
      this.$emit("onClickDownload");
    },
  },
};
</script>
<style scoped>
.gruop_check {
  overflow: hidden;
  padding: 7px 0;
  line-height: 16px;
}
.gruop_check /deep/ .check_comm {
  float: left;
  margin-left: 0;
  padding-right: 32px;
}
.gruop_check /deep/ .check_comm:nth-child(3n + 1) {
  clear: left;
  margin-left: 0;
}
.gruop_check /deep/ .check_comm:nth-child(3n) {
  padding-right: 0;
}
.gruop_check /deep/ .check_comm:nth-child(n + 4) {
  margin-top: 16px;
}
</style>
