<template>
  <Board
    :dataList="list"
    :isHorizontalScroll="isHorizontalScroll"
    :scrollWidth="scrollWidth"
    :currentPageIndex="currentPageIndex"
    :maxRowCount="maxRowCount"
    :maxPaginationCount="maxPaginationCount"
    :totalPageCount="totalPageCount"
    :showPagination="showPagination"
    @onChangePage="(pageIndex) => $emit('onChangePage', pageIndex)"
  >
    <template v-slot:colgroup>
      <slot name="colgroup" />
    </template>
    <template v-slot:tr>
      <slot name="tr" />
    </template>
    <template v-slot:rows>
      <template v-for="item in list">
        <slot name="row" :rowData="item" />
      </template>
    </template>
    <template v-slot:emptyList>
      <slot name="emptyList" />
    </template>
  </Board>
</template>

<script>
import Board from "@/components/common/board/Board";

export default {
  name: "ListPage",
  components: {
    Board,
  },
  props: {
    list: Array,
    isHorizontalScroll: {
      type: Boolean,
      default: false,
    },
    scrollWidth: String,
    currentPageIndex: Number,
    totalPageCount: Number,
    maxRowCount: Number,
    maxPaginationCount: Number,
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
