<template>
  <div class="tf_comm">
    <slot />
  </div>
</template>

<script>
export default {
  name: "InputBase",
};
</script>

<style scoped>
.tf_comm {
  display: inline-block;
  width: 100%;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  font-size: 12px;
  vertical-align: top;
  box-sizing: border-box;
}
.tf_comm input {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 4px;
  border: 0 none;
  outline: none;
  background: none;
  color: #555;
  box-sizing: border-box;
}
.tf_comm input[type="number"] {
  padding: 0 0 0 12px;
}
.tf_comm input::-webkit-input-placeholder {
  color: #bbb;
}
.tf_comm input:-moz-placeholder {
  color: #bbb;
}
.tf_comm input::-moz-placeholder {
  color: #bbb;
}
.tf_comm input:-ms-input-placeholder {
  color: #bbb;
}
.tf_error {
  border-color: #f8626c;
}
.tf_comm:focus-within {
  border: 1px solid #555;
}
.tf_comm.disabled {
  border-color: #f0f0f0;
  background-color: #fbfbfb;
}
.tf_comm.disabled input[disabled="disabled"] {
  color: #bbb;
}
</style>
