<template>
  <fragment>
    <CheckboxItem
      v-for="(item, index) in dataList"
      :id="item.id"
      :key="index"
      :text="item.text"
      :style="itemStyle"
      :isDisabled="isDisabled || item.isDisabled"
      :checkedNames.sync="checkedNamesSync"
    />
  </fragment>
</template>

<script>
import CheckboxItem from "./CheckboxItem";

export default {
  name: "Checkbox",
  components: {
    CheckboxItem,
  },
  props: {
    dataList: Array,
    checkedNames: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    itemStyle: String,
  },
  computed: {
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
};
</script>
