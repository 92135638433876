import paymentRouterPath from "@/router/paymentRouterPath";

// 전자결제 상세 paymentRouterPath
// - 저장문서함 :  docStatus 'T'
// - 진행중 문서 : docStatus 'I'
// - 완료된 문서 : docStatus 'C'
// - 반려된 문서 : docStatus 'J'
//
// - 결재전 문서 : aprvlStatus 'W'
// - 승인할 문서 : aprvlStatus 'R'
// - 승인한 문서 : aprvlStatus 'A'
// - 완료된 문서 : docStatus 'C', aprvlStatus 'ALL'
// - 반려된 문서 : docStatus 'J', aprvlStatus 'ALL'
//
// - 진행중 문서 : docStatus 'I', aprvlStatus 'N'
// - 처리된 문서 : docStatus 'C', aprvlStatus 'N'
export function getPaymentRouterPath(docStatus) {
  let ret = "";
  switch (docStatus) {
    // 내 문서함
    case "T":
      ret = paymentRouterPath.PAYMENT_DRAFT_TEMP; // 임시저장
      break;
    case "I":
      ret = paymentRouterPath.PAYMENT_DRAFT_ING_DETAIL; // 진행중
      break;
    case "C":
      ret = paymentRouterPath.PAYMENT_DRAFT_COMPLETE_DETAIL; // 완료
      break;
    case "J":
      ret = paymentRouterPath.PAYMENT_DRAFT_REJECT_DETAIL; // 반려
      break;

    // 내 결재함
    case "W":
      ret = paymentRouterPath.PAYMENT_APPROVAL_BEFORE_DETAIL; // 결재전
      break;
    case "R":
      ret = paymentRouterPath.PAYMENT_APPROVAL_RECEIVE_DETAIL; // 승인할
      break;
    case "A":
      ret = paymentRouterPath.PAYMENT_APPROVAL_ING_DETAIL; // 승인한
      break;
    case "CALL":
      ret = paymentRouterPath.PAYMENT_APPROVAL_COMPLETE_DETAIL; // 완료
      break;
    case "JALL":
      ret = paymentRouterPath.PAYMENT_APPROVAL_REJECT_DETAIL; // 반려
      break;

    // 참조 문서함
    case "IN":
      ret = paymentRouterPath.PAYMENT_CC_ING_DETAIL; // 진행중
      break;
    case "CN":
      ret = paymentRouterPath.PAYMENT_CC_COMPLETE_DETAIL; // 처리
      break;
    default:
      ret = paymentRouterPath.PAYMENT;
      break;
  }
  return ret;
}
