<template>
  <CheckboxBase>
    <input :id="id" v-model="input" type="checkbox" :value="id" :disabled="isDisabled" />
    <label :for="id"><span class="ico_purchase ico_checkbox" />{{ text }}</label>
  </CheckboxBase>
</template>

<script>
import CheckboxBase from "./CheckboxBase";

export default {
  name: "CheckboxOnlyOne",
  components: {
    CheckboxBase,
  },
  props: {
    id: String,
    isSelected: Boolean,
    text: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        return this.isSelected ? [this.id] : [];
      },
      set(value) {
        const isSelected = value.length > 0;
        this.$emit("update:isSelected", isSelected);
        this.$emit("onClickCheck");
      },
    },
  },
};
</script>
