<template>
  <div :class="['radio_comm', itemClass]">
    <input
      :id="id"
      v-model="input"
      type="radio"
      :name="name"
      :disabled="isDisabled"
      :value="id"
      @change="onChange"
    />
    <label :class="{ ir_pm: text == undefined }" :for="id">
      <span class="ico_purchase ico_radio" />
      {{ text }}
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioItem",
  props: {
    itemClass: String,
    text: String,
    name: String,
    // id: String,
    // selectedId: {
    //   type: String,
    //   default: null
    // },
    id: undefined,
    selectedId: undefined,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected() {
      return this.id === this.selectedId;
    },
    checked() {
      return this.selected ? "checked" : null;
    },
    input: {
      get() {
        return this.selectedId;
      },
      set(value) {
        this.$emit("update:selectedId", value);
      },
    },
  },
  methods: {
    onChange(e) {
      const from = this.selectedId;
      const to = e.target.value;

      this.$emit("onChange", to, from);
    },
  },
};
</script>

<style scoped>
.radio_comm {
  display: inline-block;
  position: relative;
  min-width: 16px;
  min-height: 18px;
  vertical-align: top;
}
.radio_comm input:disabled,
.radio_comm input:disabled:checked {
  cursor: auto;
}
.radio_comm input:disabled + label {
  color: #bbb;
}
.radio_comm input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 0 none;
  opacity: 0.01;
  filter: alpha(opacity=1);
  cursor: pointer;
}
.radio_comm label {
  display: block;
  padding-left: 24px;
  font-size: 12px;
  line-height: 16px;
  color: #555;
}
.radio_comm .ir_pm {
  padding-left: 0;
}
.radio_comm .ico_radio {
  position: absolute;
  top: -3px;
  bottom: 0;
  left: 0;
  margin: auto;
}
.radio_comm .ico_radio:only-child {
  top: 0;
  margin-right: 0;
}
.radio_comm + .radio_comm {
  margin-left: 32px;
}
</style>
