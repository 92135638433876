<template>
  <div class="group_form">
    <SelectBox
      class="w208"
      :dataList="kindList0"
      :isDisabled="isDisabledKind0 || isModifyMode"
      placeholder="대분류 선택"
      :value="category0"
      @update:value="onChangedKind0"
    />
    <SelectBox
      class="w208"
      :dataList="kindList1"
      :isDisabled="isDisabledKind1 || isModifyMode"
      :placeholder="
        isDisabledKind1 || isModifyMode ? '상위 분류를 선택해주세요' : '분류를 선택해주세요'
      "
      :value="category1"
      @update:value="onChangedKind1"
    />
    <SelectBox
      class="w208"
      :dataList="kindList2"
      :isDisabled="isDisabledKind2 || isModifyMode"
      :placeholder="
        isDisabledKind1 || isModifyMode ? '상위 분류를 선택해주세요' : '분류를 선택해주세요'
      "
      :value="category2"
      @update:value="onChangedKind2"
    />
  </div>
</template>

<script>
import SelectBox from "@/components/common/selectBox/SelectBox";

export default {
  name: "CategoryGroup",
  components: {
    SelectBox,
  },
  props: {
    category0: [Number, null],
    category1: [Number, null],
    category2: [Number, null],
    kindList0: Array,
    kindList1: Array,
    kindList2: Array,
  },
  computed: {
    isModifyMode() {
      return this.$route.meta.isModifyMode;
    },
    isDisabledKind0() {
      const arr = this.kindList0;
      return arr.length < 1;
    },
    isDisabledKind1() {
      const arr = this.kindList1;
      return arr.length < 1;
    },
    isDisabledKind2() {
      const arr = this.kindList2;
      return arr.length < 1;
    },
  },
  methods: {
    onChangedKind0(newId) {
      if (newId === this.category0) return;

      this.$emit("onChangedKind0", newId);
    },
    onChangedKind1(newId) {
      if (newId === this.category1) return;

      this.$emit("onChangedKind1", newId);
    },
    onChangedKind2(newId) {
      if (newId === this.category2) return;

      this.$emit("onChangedKind2", newId);
    },
  },
};
</script>
