<template>
  <div class="select_comm">
    <el-select
      v-model="input"
      popper-class="box_drop"
      :placeholder="placeholder"
      :disabled="isDisabled"
      @change="onChange"
    >
      <!-- <el-option v-for="item in dataList" :key="item.id || item.value" :label="item.desc" :value="item.value"> -->
      <el-option
        v-for="item in dataList"
        :key="item.id || item.code"
        :label="item.name || item.desc || item.code"
        :value="item.code"
        :disabled="item.isDisabled"
      />
      <template slot="prefix"> <span class="ico_purchase ico_select" /> </template>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "SelectBox",
  props: {
    dataList: { type: Array, default: null }, // { desc, value }
    value: { type: [String, Number], default: null },
    placeholder: {
      type: String,
      default: "Select",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);

        this.updateItem(value);
        this.onChangeItem(value);
      },
    },
  },
  methods: {
    onChange(value) {
      const to = value;
      const from = this.value;

      this.$emit("onChange", to, from);
    },
    onChangeItem(value) {
      const retObj = this.dataList.find((item) => item.code === value);
      this.$emit("onChangeItem", retObj);
    },
    updateItem(value) {
      const retObj = this.dataList.filter((item) => {
        let ret = null;
        if (item.code === value) {
          ret = item;
        }
        return ret;
      });

      this.$emit("updateItem", ...retObj);
    },
  },
};
</script>

<style scoped>
.select_comm {
  display: inline-block;
  width: 100%;
  height: 32px;
  vertical-align: top;
}
.select_comm .el-select {
  width: 100%;
}
.select_comm /deep/ .el-input__inner {
  display: block;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  font-size: 12px;
  line-height: 32px;
  color: #555;
}
.select_comm /deep/ .el-input__inner::-webkit-input-placeholder {
  color: #555;
}
.select_comm /deep/ .el-input__inner::-moz-placeholder {
  color: #555;
}
.select_comm /deep/ .el-input__inner:-ms-input-placeholder {
  color: #555;
}
.select_comm /deep/ .el-input__inner::-ms-input-placeholder {
  color: #555;
}
/* 인풋박스 */
.select_comm /deep/ .el-input--suffix .el-input__inner {
  padding: 0 31px 0 11px;
}
/* 인풋박스_활성화 */
.select_comm /deep/ .el-input.is-active .el-input__inner,
.select_comm /deep/ .el-select .el-input.is-focus .el-input__inner,
.select_comm /deep/ .el-input__inner:focus,
.select_comm /deep/ .el-select .el-input__inner:focus {
  border-color: #555;
}
/* 인풋박스_disabled */
.select_comm /deep/ .el-input.is-disabled .el-input__inner {
  border-color: #f0f0f0;
  background-color: #fbfbfb;
  color: #bbb;
}
.select_comm /deep/ .el-input.is-disabled .el-input__inner::-webkit-input-placeholder {
  color: #bbb;
}
.select_comm /deep/ .el-input.is-disabled .el-input__inner::-moz-placeholder {
  color: #bbb;
}
.select_comm /deep/ .el-input.is-disabled .el-input__inner:-ms-input-placeholder {
  color: #bbb;
}
.select_comm /deep/ .el-input.is-disabled .el-input__inner::-ms-input-placeholder {
  color: #bbb;
}
.select_comm /deep/ .el-input.is-disabled .el-input__prefix {
  background-color: #fbfbfb;
}
/* 아이콘 */
.select_comm /deep/ .el-input__prefix {
  top: 1px;
  left: auto;
  right: 1px;
  height: 6px;
  padding: 12px 11px;
  background-color: #fff;
}
.select_comm /deep/ .el-input__suffix {
  display: none;
}
.select_comm /deep/ .el-input__icon {
  line-height: 32px;
}
</style>
