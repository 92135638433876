import ApiService from "@/services/ApiService";

import { downloadFile } from "@/utils/fileUtils";
import { dateToyymmdd } from "@/utils/dateUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";

import excelKey from "@/constants/excelKey";

const ListPageExcelMixin = {
  data() {
    return {
      isPopExcel: false,
      isPopAlert: false,

      excelKey: -1,
      excelHeaderRawData: {},
      excelHeaderList: [],
      checkedNamesExcel: [],

      hasDate: {
        type: Boolean,
        default: true,
      },
    };
  },
  methods: {
    onClickExcelBtn(isDatesSelected) {
      if (!this.hasDate || isDatesSelected) {
        this.isPopExcel = true;

        this.getExcelHeaderList();
      } else {
        this.isPopAlert = true;
      }
    },
    async getExcelHeaderList() {
      const path = `${this.$apiPath.EXCEL}/${this.excelKey}/header`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      this.excelHeaderRawData = result.data;

      // this.excelHeaderList = this.makeHeaderList( result.data.heads );

      this.excelHeaderList = result.data.heads.map((item) => {
        let obj = Object.assign({}, item);
        obj.fields = this.makeHeaderList(item.field);

        return obj;
      });

      this.selectExcelAll();
    },
    selectExcelAll() {
      let arr = [];

      this.excelHeaderList.forEach((item) =>
        item.fields.forEach((field) => {
          arr.push(field.id);
        }),
      );

      this.checkedNamesExcel = arr;

      // this.checkedNamesExcel = this.excelHeaderList.map( item => item.id );
    },
    makeHeaderList(objData) {
      const arr = Object.keys(objData).map((k) => {
        const obj = {
          id: k,
          text: objData[k],
        };
        return obj;
      });
      return arr;
    },
    onClickDownloadExcel() {
      // const filterBar = this.$refs.filterBar;
      // const filterBarParams = filterBar.getParams();

      // let searchParms = this.makeSearchParamsByFilterBar( filterBarParams, true );

      // searchParms.size = this.currentMaxRowCount;

      // const listPage = this.$refs.listPage;
      // searchParms.page = listPage.currentPageIndex + 1;

      // const url = this.getExcelDownloadPath( searchParms );

      const url = this.getExcelDownloadUrl();

      this.downloadExcel(url);

      this.isPopExcel = false;
    },
    getExcelDownloadPath(searchParms) {
      const urlRest = makeQueryStringByObject(searchParms);

      return `${this.$apiPath.EXCEL}/${this.excelKey}${urlRest}`;
    },
    getFileName(eKey) {
      const strDate = dateToyymmdd(new Date());

      let menuName;

      switch (eKey) {
        case excelKey.ORDER:
          menuName = "발주";
          break;
        case excelKey.CONTRACT_INFO:
          menuName = "계약";
          break;
        case excelKey.BID:
          menuName = "입찰";
          break;
        case excelKey.PURCHASE_REQUEST:
          menuName = "구매신청";
          break;
        case excelKey.APPROVED_PARTNER:
          menuName = "승인된파트너";
          break;
      }

      return `${menuName}_${strDate}.xlsx`;
    },
    getCheckedField(objFields) {
      let objRet = {};

      Object.keys(objFields).forEach((k) => {
        if (this.checkedNamesExcel.some((id) => id === k)) {
          objRet[k] = objFields[k];
        }
      });

      return objRet;
    },
    async downloadExcel(path) {
      let arrHeads = this.excelHeaderRawData.heads.map((item) => {
        let obj = Object.assign({}, item);

        obj.field = this.getCheckedField(item.field);

        return obj;
      });

      const objBody = { heads: arrHeads };

      // const result = await ApiService.shared.postData( path, objBody );
      const result = await ApiService.shared.postFile(path, objBody);

      if (result.error) {
        this.alert(result.text);
        return;
      }

      if (Buffer.from(result).toString("utf8").indexOf("code") > -1) {
        const responsJson = JSON.parse(Buffer.from(result).toString("utf8"));
        if (responsJson.code != "200") {
          this.alert(responsJson.text);
          return;
        }
      }

      const fileName = this.getFileName(this.excelKey);

      downloadFile(result, fileName);
    },
    onClickCancelAlert() {
      this.isPopAlert = false;
    },
    onClickConfirmAlert() {
      this.isPopAlert = false;

      this.isPopExcel = true;

      this.getExcelHeaderList();
    },
    onClickCloseExcel() {
      this.isPopExcel = false;
    },
  },
};
export default ListPageExcelMixin;
