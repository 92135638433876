import { dateToString } from "@/utils/dateUtils";

const FilterBarMixin = {
  props: {
    statusList: Array,
    defaultStatusId: String,

    keywordDatas: Array,
    defaultStateId: String,
    defaultDates: Array,
    defaultKeywordSelectedId: String,
    defaultKeywordInputText: {
      type: String,
      default: "",
    },
    defaultAssetTypeId: String,
  },
  data() {
    return {
      stateId: this.defaultStateId,
      statusId: this.defaultStatusId,
      assetTypeId: this.defaultAssetTypeId,
      keywordSelectedId: this.defaultKeywordSelectedId,
      keywordInputText: this.defaultKeywordInputText,
      dates: this.defaultDates,

      category0: null,
      category1: null,
      category2: null,
    };
  },
  computed: {
    isDisabledStatus() {
      const hasNoData = !this.statusList || this.statusList.length === 0;
      return hasNoData;
    },
  },
  methods: {
    onClickSearch(e) {
      let ret = this.getCommonFilterbarData();

      this.$emit("onClickSearch", ret);
    },
    getCommonFilterbarData() {
      const isValidDates = this.dates && this.dates.length === 2;

      const ret = {
        status: this.stateId || this.statusId,
        keywordSelectedId: this.keywordSelectedId,
        keywordInputText: this.keywordInputText.trim(),
        startDt: isValidDates ? dateToString(this.dates[0]) : null,
        endDt: isValidDates ? dateToString(this.dates[1]) : null,

        category0: this.category0,
        category1: this.category1,
        category2: this.category2,

        assetTypeId: this.assetTypeId,
      };

      return ret;
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit("onClickExcelBtn", isDatesSelected);
    },
    resetFilterCommon() {
      this.stateId = "";
      this.statusId = "";
      this.keywordSelectedId = this.defaultKeywordSelectedId;
      this.keywordInputText = "";
      this.dates = [];

      this.category0 = null;
      this.category1 = null;
      this.category2 = null;
    },
    onClickResetFilterBar() {
      this.resetFilterCommon();

      // 초기값이 다른 경우 각 파일에서 별도 처리.
      // ex) this.defaultKeywordSelectedId = 'foo';

      // 이유는 모르겠으나 바로 동기화가 안돼서, 이렇게 처리.
      // this.$nextTick(() => {
      //   this.$refs.filterBar.resetData();
      // });
    },
  },
};
export default FilterBarMixin;
