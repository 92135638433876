import { render, staticRenderFns } from "./CheckboxBase.vue?vue&type=template&id=12072478&scoped=true&"
import script from "./CheckboxBase.vue?vue&type=script&lang=js&"
export * from "./CheckboxBase.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxBase.vue?vue&type=style&index=0&id=12072478&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12072478",
  null
  
)

export default component.exports