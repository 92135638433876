<template>
  <InputBase :class="{ disabled: isDisabled, tf_error: isError }">
    <input
      ref="input"
      v-model="input"
      :data-ellip="dataEllip"
      :class="inputClass"
      :name="name"
      :type="type"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :maxLength="maxLength"
      :disabled="isDisabled"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @change="onChange"
      @keyup.13="onKeyUpEnter"
    />
  </InputBase>
</template>

<script>
import InputBase from "@/components/common/input/InputBase.vue";
import {
  changeOnlyEnglish,
  changeOnlyEnglishUppercase,
  changeOnlyNumberAndEnglishUppercase,
  changeOnlyNumberAndEnglish,
  changeOnlyNumber,
  changeOnlyWord,
} from "@/utils/stringUtils";

export default {
  name: "Input",
  components: {
    InputBase,
  },
  props: {
    dataEllip: String,
    inputClass: String,
    value: String | Number,
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 200,
    },
    isFocusOnMount: {
      type: Boolean,
      default: false,
    },
    autocomplete: String,
    name: String,
    isOnlyEnglish: {
      type: Boolean,
      default: false,
    },
    isOnlyNumberAndEnglish: {
      type: Boolean,
      default: false,
    },
    isOnlyNumberAndEnglishUppercase: {
      type: Boolean,
      default: false,
    },
    isOnlyEnglishUppercase: {
      type: Boolean,
      default: false,
    },
    isOnlyNumber: {
      type: Boolean,
      default: false,
    },
    isOnlyWord: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        if (this.isOnlyEnglish) {
          if (this.value === undefined) {
            return;
          }
          let validText = changeOnlyEnglish(this.value);
          if (validText !== this.value) {
            this.$emit("onChange", validText);
            this.$emit("update:value", validText);
          }
        }
        if (this.isOnlyNumberAndEnglishUppercase) {
          if (this.value === undefined) {
            return;
          }
          let validText = changeOnlyNumberAndEnglishUppercase(this.value);
          if (validText !== this.value) {
            this.$emit("onChange", validText);
            this.$emit("update:value", validText);
          }
        }
        if (this.isOnlyEnglishUppercase) {
          if (this.value === undefined) {
            return;
          }
          let validText = changeOnlyEnglishUppercase(this.value);
          if (validText !== this.value) {
            this.$emit("onChange", validText);
            this.$emit("update:value", validText);
          }
        }
        if (this.isOnlyNumberAndEnglish) {
          if (this.value === undefined) {
            return;
          }
          let validText = changeOnlyNumberAndEnglish(this.value);
          if (validText !== this.value) {
            this.$emit("onChange", validText);
            this.$emit("update:value", validText);
          }
        }
        if (this.isOnlyNumber) {
          if (this.value === undefined) {
            return;
          }
          let validText = changeOnlyNumber(this.value);
          if (validText !== this.value) {
            this.$emit("onChange", validText);
            this.$emit("update:value", validText);
          }
        }
        if (this.isOnlyWord) {
          if (this.value === undefined) {
            return;
          }
          let validText = changeOnlyWord(this.value);
          if (validText !== this.value) {
            this.$emit("onChange", validText);
            this.$emit("update:value", validText);
          }
        }
        if (this.maxLength && this.value.length > this.maxLength) {
          this.$emit("update:value", this.value.slice(0, this.maxLength));
        }
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  mounted() {
    if (this.isFocusOnMount) {
      this.$refs.input.focus();
    }
  },
  methods: {
    blur() {
      // 외부에서 호출 될 메서드.
      this.$refs.input.blur();
    },
    onFocus(e) {
      this.$emit("update:focus", e);
    },
    onBlur(e) {
      this.$emit("update:blur", e);
    },
    onKeyUpEnter(e) {
      this.blur();

      this.$emit("onEnterKey", e);
    },
    onInput(e) {
      this.$emit("onInput", e);
    },
    onChange(e) {
      this.$emit("onChange", e);
    },
  },
};
</script>
