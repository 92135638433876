export const board = {
  MAX_PAGINATION_COUNT: 10,

  DEFAULT_MAX_ROW_COUNT: 30,
};

export const file = {
  INVALID_FILE_EXTENSIONS: [".ico", ".exe", ".dmg", ".gif"],
};

export const MAIN_NOTICE_MAX_COUNT = 7; // 파트너 - 로그인 메인 - 공지 글 개수 제한.

export const HANDLE_TITLE_MAX_LENGTH = 30; // 취급 품목 제목 글자 수 제한.
