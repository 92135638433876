<template>
  <div class="tbl_head">
    <div v-if="hasHeadLeft" class="area_left">
      <h3 v-if="title" class="tit_tbl">
        {{ title }}<span v-if="isEssential" class="ico_purchase ico_star">필수항목</span>
      </h3>
      <slot name="headLeft" />
    </div>
    <div v-if="hasHeadRight" class="area_right">
      <slot name="headRight" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHead",
  props: {
    title: String,
    isEssential: Boolean,
  },
  computed: {
    hasHeadRight() {
      return this.$slots["headRight"];
    },
    hasHeadLeft() {
      return this.$slots["headLeft"] || this.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.tbl_head {
  max-height: 32px;
  padding-bottom: 8px;
  font-size: 0;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.tbl_head .area_left:only-child .tit_tbl {
  padding-top: 0;
}
.tbl_head .tit_tbl {
  display: inline-block;
  padding-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #888;
  vertical-align: bottom;
}
.tbl_head .select_comm {
  width: 88px;
}
.tbl_head button + .select_comm,
.tbl_head button + .screen_out + .select_comm {
  margin-left: 8px;
}
.tbl_head .area_right .desc_tip {
  padding-top: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #555;
}
.tbl_head .area_right .tit_util {
  display: inline-block;
  padding-right: 12px;
  font-weight: normal;
  font-size: 12px;
  line-height: 31px;
  vertical-align: top;
}
.tbl_head .area_right button + button {
  margin-left: 8px;
}

.tbl_head .area_right .desc_alert.tc_red {
  display: inline-block;
  padding: 6px 16px;
  border: 1px solid #f8626c;
  font-size: 12px;
  line-height: 18px;
  background-color: #fef7f7;
  vertical-align: top;
}
.tbl_head .area_right .desc_alert.tc_red + button {
  margin-left: 8px;
}
</style>
