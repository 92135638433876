<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">계약관리</h2>
      </div>
      <div class="body_section">
        <ContractInfoListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          :defaultContractDiv="defaultContractDiv"
          :defaultCalculateYn="defaultCalculateYn"
          :defaultCalculateMonth="defaultCalculateMonth"
          :defaultIsMyWork="defaultIsMyWork"
          :contractDivList="contractDivList"
          :calculateYnList="calculateYnList"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-if="$isAdmin" v-slot:headLeft>
            <button
              v-if="getCanNewWriteProcess"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickGoToWrite"
            >
              <span class="ico_purchase ico_cross_w" />계약 등록
            </button>
          </template>
          <template v-slot:headRight>
            <div class="group_form">
              <template v-if="$isAdmin">
                <button
                  class="btn_tertiary btn_medium"
                  :disabled="computedCheckedList.length === 0"
                  @click="onClickSendAlarm"
                >
                  <span class="material-icons-outlined send-alarm"> forward_to_inbox </span
                  >전자서명요청
                  <strong>{{ `(${computedCheckedList.length})` }}</strong>
                </button>
                <span class="bar_view"> </span>
              </template>

              <SelectBoxPageRange
                :currentMaxRowCount.sync="size"
                @update:currentMaxRowCount="onChangeRowCount"
              />
            </div>
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :isHorizontalScroll="true"
          :scrollWidth="'1900px'"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 체크박스 -->
            <col style="width: 40px" />
            <!-- 계약구분 -->
            <!-- <col style="width: 80px" /> -->
            <!-- 계약상태 -->
            <col style="width: 100px" />
            <!-- 차수 -->
            <!-- <col style="width: 60px" /> -->
            <!-- 계약번호  -->
            <col style="width: 160px" />
            <!-- 계약명 -->
            <col />
            <!-- 프로젝트 코드 -->
            <col style="width: 160px" />
            <!-- 프로젝트명 -->
            <col style="width: 180px" />
            <!-- 파트너사 -->
            <col style="width: 100px" />
            <!-- 계약금액 -->
            <col style="width: 120px" />
            <!-- 계약시작일 -->
            <col style="width: 80px" />
            <!-- 계약종료일 -->
            <col style="width: 80px" />
            <!-- 계약체결일 -->
            <col style="width: 80px" />
            <!-- 검수담당자 -->
            <col style="width: 160px" />
            <!-- 구매담당자 -->
            <col style="width: 160px" />
            <!-- 증권번호 -->
            <col style="width: 180px" />
            <!-- 연결상태 (견적,공고) -->
            <!-- <col style="width: 60px" /> -->
            <!-- 전자결재 (진행,완료,반려) -->
            <col style="width: 60px" />
          </template>
          <template v-slot:tr>
            <th>
              <CheckboxItem
                ref="checkAll"
                :isDisabled="!$isAdmin"
                @update:checkedNames="onClickedAll"
              />
            </th>
            <!-- <th>계약구분</th> -->
            <th>계약상태</th>
            <!-- <th>계약차수</th> -->
            <th>계약번호</th>
            <th>계약명</th>
            <th>프로젝트코드</th>
            <th>프로젝트명</th>
            <th>파트너사</th>
            <th>계약금액</th>
            <th>계약시작일</th>
            <th>계약종료일</th>
            <th>계약체결일</th>
            <th>검수담당자</th>
            <th>구매담당자</th>
            <th :data-ellip="'선급/계약/하자'">보증보험-증권번호</th>
            <!-- <th>연결상태</th>-->
            <th>전자결재</th>
          </template>
          <template v-slot:row="slotProps">
            <ContractInfoListLine :rowData="slotProps.rowData" :checkedNames.sync="checkedNames" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="15" class="td_empty">내역이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup>
      <!-- <AlertPopup
        v-if="isPopAlert"
        alertText="기간을 설정하지 않으셨습니다.<br>모든 데이터를 다운로드 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelAlert"
        @onClickPrimary="onClickConfirmAlert"
      />
      <ExcelDonwloadPopup
        v-if="isPopExcel"
        :excelDataList="excelHeaderList"
        :checkedNames.sync="checkedNamesExcel"
        :calculateSeletedId.sync="calculateSeletedId"
        :excelRangeDataList="excelRangeDataList"
        @onClickClose="onClickCloseExcel"
        @onClickDownload="onClickDownloadExcel"
      /> -->
    </template>
  </PageWithLayout>
</template>

<script>
import { mapState } from "vuex";
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import ListPageContractType from "@/components/shared/ListPageContractType";
import TableHead from "@/components/shared/TableHead";
import SelectBox from "@/components/common/selectBox/SelectBox";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

import ContractInfoListFilterBar from "@/components/shared/contract/list/ContractInfoListFilterBar";
import ContractInfoListLineInspect from "@/components/shared/contract/list/ContractInfoListLineInspect";
import ContractInfoListLine from "@/components/shared/contract/list/ContractInfoListLine";
import ContractInfoListLine2 from "@/components/shared/contract/list/ContractInfoListLine2";

import ApiService from "@/services/ApiService";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";
import { downloadFile } from "@/utils/fileUtils";

import { GET_CONTRACT_LIST } from "@/store/modules/contract/action";

import { dateToString, stringToDate } from "@/utils/dateUtils";

import excelKey from "@/constants/excelKey";
import LocalStorageManager from "@/LocalStorageManager";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { makeQueryStringByObject } from "@/utils/urlUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "ContractInfoList",
  components: {
    PageWithLayout,
    ListPage,
    ListPageContractType,
    TableHead,
    SelectBox,
    SelectBoxPageRange,
    CheckboxItem,
    ContractInfoListFilterBar,
    ContractInfoListLineInspect,
    ContractInfoListLine,
    ContractInfoListLine2,
    AlertPopup,
    ExcelDonwloadPopup,
  },
  mixins: [PageMixin, ListPageMixin],
  props: {
    rowIndex1: Number,
    rowIndex2: Number,
  },
  data() {
    return {
      API_PATH: this.$apiPath.CONTRACT,

      defaultKeywordSelectedId: "projectName",

      defaultContractDiv: "0",
      defaultCalculateYn: "0",
      defaultCalculateMonth: false,
      defaultIsMyWork: false,

      calculateRange: "0",
      calculateSeletedId: "M00",

      dateNow: new Date(),

      rowIndex1to2: Number,
      rowIndex2to1: Number,
      isNoDataList: false,

      size: 100, // 계약만 100개가 디폴트.

      excelKey: excelKey.CONTRACT_INFO,

      checkedNames: [],
    };
  },

  computed: {
    ...mapState({
      calculateRangeList: (state) => state.contract.calculateRangeList,
      statusList: (state) => {
        const arr = [{ code: "0", name: "전체" }];
        return arr.concat(state.contract.statusList);
      },
      calculateYnList: (state) => state.contract.calculateTypeList,
      contractDivList: (state) => {
        const arr = [{ code: "0", name: "전체" }];
        return arr.concat(state.contract.typeList);
      },
      keywordList: (state) => state.contract.keywordList,
    }),

    isMultiMode() {
      return this.calculateRange !== "0";
    },
    defaultsettelType() {
      switch (this.calculateRange) {
        case "0":
          return 0;
        case "M00":
          return 1;
        case "M03":
          return 2;
        case "M06":
          return 3;
        case "M12":
          return 4;
      }
      return 0;
    },
    isYearMode() {
      return this.calculateRange === "M12";
    },
    excelRangeDataList() {
      if (this.calculateRangeList.length === 0) return [];

      const arrExcept0 = this.calculateRangeList.slice(1);

      const arrForRadio = arrExcept0.map((item) => {
        const obj = Object.assign({}, item);

        obj.id = item.code;
        obj.text = item.name;

        return obj;
      });

      return arrForRadio;
    },

    getCanNewWriteProcess() {
      if (!this.isShowAccessBtn(this.menuId.CONTRACT_WRITE)) return false;
      return LocalStorageManager.shared.getCanNewWriteProcess();
    },
    computedContractStatus() {
      return this.$route.query.contractStatus;
    },
    computedCheckedList() {
      //여기서 전달데이터 가공
      const ret = this.checkedNames.map((item) => {
        return item.contractId;
      });
      return ret;
    },
  },
  watch: {
    checkedNames(newVal, oldVal) {
      if (this.dataList.length === 0 || this.dataList.length !== newVal.length) {
        this.$refs.checkAll.forceUnChecked();
      } else {
        this.$refs.checkAll.forceChecked();
      }
    },
  },
  created() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_CONTRACT_LIST).then(() => {
      this.getDataInit(params);
    });
  },

  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordList);

      // const objStatus = this.checkQueryStatus(query);

      const objDates = this.checkQueryDates(query, "contractStartDate1", "contractStartDate2");

      //const objCateNum = this.checkQueryCateNum(query);

      const ret = Object.assign({}, objPaging, objKeyword, objDates);
      const { contractStatus, contractDiv, calculateYn, calculateMonth, calculateRange, isMyWork } =
        query;

      if (contractStatus) {
        this.defaultStateId = contractStatus;
        ret.contractStatus = contractStatus;
      }
      if (contractDiv) {
        this.defaultContractDiv = contractDiv;
        ret.contractDiv = contractDiv;
      }
      // if (calculateYn) {
      //   this.defaultCalculateYn = calculateYn;
      //   ret.calculateYn = calculateYn;
      // }
      // if (calculateMonth) {
      //   this.defaultCalculateMonth = calculateMonth === 'Y';
      //   ret.calculateMonth = calculateMonth;
      // }
      // if (calculateRange) {
      //   this.calculateRange = query.calculateRange;
      //   ret.calculateRange = calculateRange;
      // }
      if (isMyWork) {
        this.defaultIsMyWork = isMyWork === "Y";
        ret.isMyWork = isMyWork;
      }
      return ret;
    },
    getFilterbarParams(objData, isForExcel) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        status,
        category0,
        category1,
        category2,
        contractDiv,
        calculateYn,
        calculateMonth,
        calculateRange,
        isMyWork,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["contractStartDate1"] = startDt;
        params["contractStartDate2"] = endDt;
      }

      if (status) {
        // params.status = status;
        params.contractStatus = status;
      }

      const cateNum = category2 || category1 || category0;
      if (cateNum) {
        params.cateNum = cateNum;
      }

      params.isMyWork = isMyWork ? "Y" : "N";

      return params;
    },

    onClickGoToWrite() {
      this.$router.push({
        path: `${this.$routerPath.CONTRACT_WRITE}`,
      });
    },
    mouseOverLine1(index) {
      this.rowIndex1to2 = index;
    },
    mouseOverLine2(index) {
      this.rowIndex2to1 = index;
    },

    onClickedAll(checked) {
      if (checked) {
        this.checkedNames = this.dataList;
      } else {
        this.checkedNames = [];
      }
    },
    onClickSendAlarm() {
      const params = {
        text: `총 ${this.computedCheckedList.length}건의 계약전자서명요청 알림을 발송 하시겠습니까?`,
        onClickY: () => {
          this.sendAlarm();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async sendAlarm() {
      const obj = {};
      obj.manualType = "CONTRACT_SIGN_REQUEST";
      obj.targetPk = this.computedCheckedList;
      const result = await ApiService.shared.post(`${this.$apiPath.ALRIM}/sendManual`, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.alert(`총 ${this.computedCheckedList.length}건 발송되었습니다.`);
      const params = this.checkUrlQuery();
      this.$store.dispatch(GET_CONTRACT_LIST).then(() => {
        this.getDataInit(params);
      });
      this.checkedNames = [];
    },
    async getData(params) {
      await this.$store.dispatch(PROGRESS_START_ACTION);

      if (this.ADD_PARAMS) {
        params = Object.assign(params, this.ADD_PARAMS);
      }

      const urlRest = makeQueryStringByObject(params);
      const url = this.API_PATH + urlRest;

      const result = await ApiService.shared.getData(url);

      await this.$store.dispatch(PROGRESS_END_ACTION);

      const { code, data, text, total } = result;
      if (code !== "200") {
        this.alert(text ? text : code);
        return;
      }
      if (code === "200") {
        this.dataList = data;

        // params.page = "1";
        // params.size = this.size;
        // this.totalPageCount = parseInt(total);
      } else {
        this.dataList = [];
        //this.totalPageCount = 0;
      }

      //  this.$emit("onChangedList", this.dataList);

      await this.getListCount(params);
    },
    async getListCount(params) {
      // /api/contract/count
      await this.$store.dispatch(PROGRESS_START_ACTION);

      if (this.ADD_PARAMS) {
        params = Object.assign(params, this.ADD_PARAMS);
      }

      const urlRest = makeQueryStringByObject(params);
      const url = "/contract/count";

      const result = await ApiService.shared.getData(`${url}?${urlRest}`);

      await this.$store.dispatch(PROGRESS_END_ACTION);

      const { code, data, text, total } = result;
      if (code !== "200") {
        this.alert(text ? text : code);
        return;
      }
      if (code === "200") {
        const { page, size, total } = data;
        params.page = "1";
        params.size = this.size;
        this.totalPageCount = parseInt(total);
      } else {
        //this.dataList = [];
        this.totalPageCount = 0;
      }

      this.$emit("onChangedList", this.dataList);
    },

    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/contract`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) return;
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: "arraybuffer",
      });
      this.$store.dispatch(PROGRESS_END_ACTION);
      downloadFile(result, `계약관리_${this.moment().format("YYYYMMDD")}.xlsx`);
    },
  },
};
</script>
<style lang="scss" scoped>
.send-alarm {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
}
</style>
