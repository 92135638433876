<template>
  <tr :class="{ tr_select: isSelected }">
    <!-- 체크 -->
    <td>
      <CheckboxItem :id="rowData" :isDisabled="!$isAdmin" :checkedNames.sync="checkedNamesSync" />
    </td>

    <!-- 계약상태 -->
    <td>{{ rowData.contractStatus || "" }}</td>

    <!-- 계약번호  -->
    <td>{{ rowData.contractCid }}</td>
    <!-- 계약명 -->
    <td :class="'td_ellip align_left'">
      <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.description || ''"
        v-html="rowData.description || ''"
      >
        {{ rowData.description }}
      </router-link>
    </td>
    <!-- 프로젝트코드 -->
    <td>{{ rowData.projectCode }}</td>
    <!-- 프로젝트명 -->
    <td
      class="td_ellip align_left"
      :data-ellip="`[${rowData.projectCode}] ${rowData.projectName}`"
      v-html="rowData.projectName"
    ></td>
    <!-- 파트너사 -->
    <td class="td_ellip align_left">
      <button
        class="link_subject"
        :data-ellip="rowData.companyName || ''"
        @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${rowData.userNum}`)"
      >
        {{ rowData.companyName || "" }}
      </button>
    </td>
    <!-- 계약금액 -->
    <td class="align_right">{{ rowData.finalPrice | currency }}</td>
    <!-- 계약시작일 -->
    <td>{{ rowData.contractStartDate | dateStringFormat }}</td>
    <!-- 계약종료일 -->
    <td>{{ rowData.contractEndDate | dateStringFormat }}</td>
    <!-- 계약체결일 -->
    <td>{{ rowData.signDate.substr(0, 10) }}</td>
    <!-- 검수담당자 -->
    <td>{{ rowData.inspecterName }}({{ rowData.inspecterLoginId }})</td>
    <!-- 구매담당자 -->
    <td>{{ rowData.purchasePersonName }}({{ rowData.purchasePersonId }})</td>

    <!-- 보증보험 증권번호 -->
    <td>
      <ul v-if="computedSuretyList.length > 0" class="align_left">
        <li v-for="(item, index) in computedSuretyList" :key="index">
          <button class="link_subject align_left" @click="onClickSuretyView(item)">
            {{ `(${item.name})${item.code}` }}
          </button>
        </li>
      </ul>
      <span v-else>{{ "-" }}</span>
    </td>

    <!-- 연결상태 (견적,공고) -->
    <!-- <td>{{ '-' }}</td> -->
    <!-- 전자결재 (진행,완료,반려) -->
    <td>
      <template v-if="rowData.docStatus">
        <button
          :class="['link_subject', 'align_center']"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button>
        <!-- 
          <button
          v-if="isMyPayment"
          :class="['link_subject', 'align_center']"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button><button v-else :class="['align_center']" @click="onClickPaymentReject">
          {{ rowData.docStatusName }}
        </button> -->
      </template>
      <template v-else>
        {{ "-" }}
      </template>
    </td>
  </tr>
</template>

<script>
import mime from "mime-types";
import LocalStorageManager from "@/LocalStorageManager";
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import { makePathWithQuery } from "@/utils/urlUtils";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import { downloadFile, ObjectURLFile } from "@/utils/fileUtils";

import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "ContractInfoListLine",
  components: {
    ListRow,
    CheckboxItem,
  },
  mixins: [ListLineMixin],
  props: {
    checkedNames: Array,
    rowData: Object,
  },
  data() {
    return {
      suretyList: [],
    };
  },
  computed: {
    toPath() {
      const { contractId } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.CONTRACT_VIEW, contractId, this.$route.query);
      return path;
    },

    computedSuretyList() {
      const {
        advancePaymentSurety,
        advancePaymentSuretyFile,
        advancePaymentSuretyFileName,
        advancePaymentSuretyFileType,
        contractSurety,
        contractSuretyFile,
        contractSuretyFileName,
        contractSuretyFileType,
        defectSurety,
        defectSuretyFile,
        defectSuretyFileName,
        defectSuretyFileType,
      } = this.rowData;

      let ret = [];

      if (advancePaymentSurety || advancePaymentSuretyFile) {
        ret.push({
          name: "선급",
          code: advancePaymentSurety,
          filePath: advancePaymentSuretyFile,
          fileName: advancePaymentSuretyFileName,
          fileType: advancePaymentSuretyFileType,
        });
      }
      if (contractSurety || contractSuretyFile) {
        ret.push({
          name: "계약",
          code: contractSurety,
          filePath: contractSuretyFile,
          fileName: contractSuretyFileName,
          fileType: contractSuretyFileType,
        });
      }
      if (defectSurety || defectSuretyFile) {
        ret.push({
          name: "하자",
          code: defectSurety,
          filePath: defectSuretyFile,
          fileName: defectSuretyFileName,
          fileType: defectSuretyFileType,
        });
      }

      return ret;
    },
    isMyPayment() {
      const { username } = LocalStorageManager.shared.getUserData();
      const { purchasePersonId } = this.rowData;
      return username === purchasePersonId;
    },
    computedPaymentPath() {
      const { docStatus } = this.rowData;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
    isSelected() {
      const ret = this.checkedNames.find(
        (item) => String(item.contractId) === String(this.rowData.contractId),
      );

      return !!ret;
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
  methods: {
    onClickPaymentReject() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: "해당 기안의 열람 권한이 없습니다.",
      });
    },
    async onClickSuretyView(item) {
      const { filePath, fileName, fileType } = item;
      if (!filePath) {
        this.alert("파일이 존재하지 않습니다.");
        return;
      }
      const result = await ApiService.shared.getFile(`${this.$apiPath.ATTACH_FILE}/${filePath}`);
      const mimeType = mime.lookup(fileName);
      if (mimeType === "image/png" || mimeType === "image/jpeg" || mimeType === "application/pdf") {
        const url = ObjectURLFile(result, mimeType);
        this.$windowOpen(url);
      } else {
        downloadFile(result, fileName);
      }
    },
  },
};
</script>
