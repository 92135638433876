import { ALERT_ACTION } from "@/store/modules/alert/action";
import menuId from "@/constants/menuId";

const PageMixin = {
  data() {
    return {
      menuId: menuId,
    };
  },
  methods: {
    alert(strMess, cb) {
      if (!strMess) {
        return;
      }
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: strMess,
        onClickY: cb,
      });
    },
    createSummernote(options) {
      const summernote = $("#summernote");

      if (!summernote) return;

      const self = this;

      var objBasic = {
        height: 200,
        maximumImageFileSize: 1048576, // 1메가.
        callbacks: {
          onImageUploadError: function (msg) {
            // upload image to server and create imgNode...
            self.alert(msg);
          },
        },
      };

      if (options) {
        Object.assign(objBasic, options);
      }

      summernote.summernote(objBasic);
    },
    isShowAccessBtn(menuId) {
      const roleList = this.$store.getters["getRoleList"];
      return roleList.some((item) => item === menuId);
    },
  },
};
export default PageMixin;
